import { FC } from 'react';
import { FlowGroup } from '@data/eve-sdk-web';
import { millify } from 'millify';
import {
  isPast,
  fromUnixTime,
  differenceInDays,
  formatDistanceStrict,
} from 'date-fns';
import { de as locale } from 'date-fns/locale';
import { EveIcon } from '../components/EveIcon';
import { Badge } from '../components/Badge';
import { Spacer } from '../components/Spacer';
import { getImageUrl } from '../utils/images';
import classNames from 'classnames';
import { Comments } from '../components/Icon';

import styles from './LaneTeaser.module.scss';

export type LaneTeaserProps = {
  group: FlowGroup;
  color?: string;
  onClick: () => void;
};

export const LaneTeaser: FC<LaneTeaserProps> = ({ group, color, onClick }) => {
  const isNew = Boolean(
    group.teaser.startDate &&
      differenceInDays(new Date(), fromUnixTime(group.teaser.startDate)) < 1,
  );
  const isExpired = Boolean(
    group.teaser.endDate && isPast(fromUnixTime(group.teaser.endDate)),
  );
  const remaining = group.teaser.endDate
    ? formatDistanceStrict(fromUnixTime(group.teaser.endDate), new Date(), {
        locale,
      })
    : undefined;

  return (
    <div
      className={classNames(
        styles['lane-teaser'],
        styles[
          `lane-teaser--${(group.teaser.type ?? 'default').toLowerCase()}`
        ],
        {
          [styles['lane-teaser--cover']]: Boolean(group.teaser.image?.url),
        },
      )}
      style={{
        '--color': color,
        backgroundImage: group.teaser.image?.url
          ? `url(${getImageUrl(group.teaser.image.url, 'teaser')})`
          : undefined,
      }}
      onClick={() => onClick()}>
      {group.teaser.type === 'comments' ? (
        <div className={styles['lane-teaser__icon']}>
          <Comments size={150} />
        </div>
      ) : null}
      <div className={styles['lane-teaser__content']}>
        <div className={styles['lane-teaser__badges']}>
          {isNew ? <Badge label="NEU" variant="emphasized" /> : null}
          {isExpired ? (
            <Badge label="Beendet" />
          ) : remaining ? (
            <Badge icon="Clock" label={remaining} />
          ) : null}
          <Spacer />
          {!isNew &&
          group.teaser.totalCount &&
          group.teaser.totalCount > 500 ? (
            <Badge
              icon={group.teaser.type === 'comments' ? 'Comments' : 'Touch'}
              label={millify(group.teaser.totalCount)}
            />
          ) : null}
          {group.teaser.userProgress === 'completed' ? (
            <Badge icon="Checkmark" variant="positive" />
          ) : null}
        </div>
        <div className={styles['lane-teaser__label']}>{group.teaser.label}</div>
        {group.teaser.cta ? (
          <div className={styles['lane-teaser__action']}>
            {group.teaser.cta.icon ? (
              <span className={styles['lane-teaser__action-icon']}>
                <EveIcon name={group.teaser.cta.icon} size={16} />
              </span>
            ) : null}
            <span className={styles['lane-teaser__action-label']}>
              {group.teaser.cta.label}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
