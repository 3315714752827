import { FC } from 'react';
import { FlowGroup } from '@data/eve-sdk-web';

import styles from './Floating.module.scss';

export type FloatingProps = {
  groups: FlowGroup[];
  onShowGroup: (group: FlowGroup) => void;
};

export const Floating: FC<FloatingProps> = ({ children }) => {
  return <div className={styles.floating}>{children}</div>;
};
