// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Guess_guess__search__3jv7L{display:flex;opacity:var(--ds-emphasis-medium)}.Guess_guess__clear__3fZre{display:flex;opacity:var(--ds-emphasis-medium);cursor:pointer}", "",{"version":3,"sources":["webpack://src/fragments/steps/Guess.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,iCAAA,CAGF,2BACE,YAAA,CACA,iCAAA,CACA,cAAA","sourcesContent":[".guess__search {\n  display: flex;\n  opacity: var(--ds-emphasis-medium);\n}\n\n.guess__clear {\n  display: flex;\n  opacity: var(--ds-emphasis-medium);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"guess__search": "Guess_guess__search__3jv7L",
	"guess__clear": "Guess_guess__clear__3fZre"
};
export default ___CSS_LOADER_EXPORT___;
