import { FC } from 'react';
import { CommonStepProps } from './steps/types';
import { Info } from './steps/Info';
import { VideoInfo } from './steps/VideoInfo';
import { Results } from './steps/Results';
import { ThankYou } from './steps/ThankYou';
import { Boxes } from './steps/Boxes';
import { Battle } from './steps/Battle';
import { VideoBoxes } from './steps/VideoBoxes';
import { VideoBattle } from './steps/VideoBattle';
import { PlayerScore } from './steps/PlayerScore';
import { Guess } from './steps/Guess';
import { Raffle } from './steps/Raffle';
import { Comments } from './steps/Comments';
import {
  isInfoStep,
  isVideoInfoStep,
  isResultsStep,
  isThankYouStep,
  isBoxStep,
  isBattleStep,
  isVideoBoxesStep,
  isVideoBattleStep,
  isPlayerScoreStep,
  isGuessStep,
  isRaffleStep,
  isCommentsStep,
} from '@data/eve-sdk-web';

export const StepSwitch: FC<CommonStepProps> = (props) => {
  const { step, ...rest } = props;

  if (isInfoStep(step)) {
    return <Info step={step} {...rest} />;
  }

  if (isVideoInfoStep(step)) {
    return <VideoInfo step={step} {...rest} />;
  }

  if (isResultsStep(step)) {
    return <Results step={step} {...rest} />;
  }

  if (isThankYouStep(step)) {
    return <ThankYou step={step} {...rest} />;
  }

  if (isBoxStep(step)) {
    return <Boxes step={step} {...rest} />;
  }

  if (isBattleStep(step)) {
    return <Battle step={step} {...rest} />;
  }

  if (isVideoBoxesStep(step)) {
    return <VideoBoxes step={step} {...rest} />;
  }

  if (isVideoBattleStep(step)) {
    return <VideoBattle step={step} {...rest} />;
  }

  if (isPlayerScoreStep(step)) {
    return <PlayerScore step={step} {...rest} />;
  }

  if (isGuessStep(step)) {
    return <Guess step={step} {...rest} />;
  }

  if (isRaffleStep(step)) {
    return <Raffle step={step} {...rest} />;
  }

  if (isCommentsStep(step)) {
    return <Comments step={step} {...rest} />;
  }

  return null;
};
