import { ContentResource, SomMarketing } from '@p7s1/oasis-types';

export const somMarketingActive: SomMarketing = {
  enabled: true,
  config: {
    id: 'pro7',
  },
  taxonomy: {
    channels: ['dave'],
    content: 'video',
  },
  device: {
    mode: 'auto',
  },
  video: {
    blocks: {
      pre: {
        enabled: true,
        slots: {
          preroll: {
            enabled: true,
            count: 1,
          },
        },
      },
      mid: {
        enabled: false,
      },
      post: {
        enabled: false,
      },
    },
  },
};

export const somMarketingInactive: SomMarketing = {
  enabled: false,
  config: {
    id: 'pro7',
  },
  taxonomy: {
    channels: ['dave'],
    content: 'video',
  },
  device: {
    mode: 'auto',
  },
  video: {
    blocks: {
      pre: {
        enabled: false,
      },
      mid: {
        enabled: false,
      },
      post: {
        enabled: false,
      },
    },
  },
};

const enrichSingleContentResource = (
  content: Partial<ContentResource>,
  ads?: boolean,
): ContentResource => {
  return {
    ...content,
    somMarketing: ads ? somMarketingActive : somMarketingInactive,
    midrollOffsets: [5, 10, 30],
    overlayOffsets: [5, 10, 30],
  } as ContentResource;
};

export const enrichContentResource = (
  content: Partial<ContentResource> | Partial<ContentResource>[],
  ads?: boolean,
): ContentResource | ContentResource[] => {
  if (Array.isArray(content)) {
    return content.map((item) => enrichSingleContentResource(item, ads));
  }
  return enrichSingleContentResource(content, ads);
};
