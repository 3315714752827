import { AnimatePresence, motion } from 'framer-motion';
import { FC, ReactNode, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import { Countdown } from './Countdown';

export type TransitionProps = {
  start?: number;
  end?: number;
  countdown?: boolean;
  decorator?: (children: ReactNode) => ReactNode;
};

export const Transition: FC<TransitionProps> = ({
  start = 0,
  end = 0,
  countdown,
  decorator = (element) => element,
  children,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(!start && !end);

  useInterval(() => {
    setIsVisible(true);
  }, start || null);

  useInterval(() => {
    setIsVisible(false);
  }, end || null);

  return (
    <AnimatePresence>
      {isVisible ? (
        <motion.div
          key="action"
          initial={{ opacity: 0, scale: 0.9, y: '50%' }}
          animate={{ opacity: 1, scale: 1, y: '0%' }}
          exit={{ opacity: 0, scale: 0.9, y: '50%' }}>
          {countdown
            ? decorator(<Countdown duration={(end - start) / 1000} />)
            : null}
          {children}
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
