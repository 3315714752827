import { Environment } from '@data/eve-sdk-web';
import { Config } from '@data/eve-sdk-web-ui-types';
import { createContext, useContext } from 'react';

export const defaultConfig: Config = {
  env: 'prod',
  miraToken: '',
  name: '',
  context: '',
  variant: 'lane',
  theme: undefined,
  colors: '',
  tags: '',
} as const;

export const ConfigContext = createContext<Config | undefined>(undefined);

export const useConfig = (): Config =>
  useContext(ConfigContext) ?? defaultConfig;

export const deriveEnvironmentFromIntegration = (): Environment => {
  if (document.location.hostname.startsWith('integration.')) {
    return 'int';
  }

  if (document.location.hostname.startsWith('preprod.')) {
    return 'preprod';
  }

  return 'prod';
};

export const deriveConfigFromIntegration = (): Partial<Config> => {
  const env = deriveEnvironmentFromIntegration();
  return {};
};
