import { FC } from 'react';
import { Button } from '../components/Button';
import { Copy } from '../components/Copy';
import { Headline } from '../components/Headline';
import { Modal } from '../components/Modal';
import { Stack } from '../components/Stack';
import { triggerAuth } from '../utils/auth';

import styles from './AuthWall.module.scss';

export type AuthWallProps = {
  onClose: () => void;
};

export const AuthWall: FC<AuthWallProps> = ({ onClose }) => {
  return (
    <Modal
      id="auth"
      variant="small"
      align="center"
      open
      floating
      onClose={() => onClose()}>
      <Stack>
        <svg viewBox="0 0 52 64" className={styles['auth-wall__illustration']}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.5 6.74994C19.0442 6.74994 13 12.7941 13 20.2499C13 27.7058 19.0442 33.7499 26.5 33.7499C33.9558 33.7499 40 27.7058 40 20.2499C40 12.7941 33.9558 6.74994 26.5 6.74994ZM26.5 9.74994C32.299 9.74994 37 14.451 37 20.2499C37 26.0489 32.299 30.7499 26.5 30.7499C20.701 30.7499 16 26.0489 16 20.2499C16 14.451 20.701 9.74994 26.5 9.74994Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.1466 38.2499C42.5444 38.2499 50.3855 45.7558 51.4906 55.5823C51.5832 56.4055 50.9909 57.148 50.1677 57.2405C49.3444 57.3331 48.602 56.7408 48.5094 55.9176C47.5833 47.6826 41.1536 41.4258 33.4719 41.2536L33.1466 41.2499H18.8508C11.0334 41.2499 4.43862 47.555 3.49042 55.8962C3.39684 56.7193 2.65371 57.3107 1.83059 57.2171C1.00746 57.1236 0.416042 56.3804 0.509614 55.5573C1.61226 45.8576 9.27831 38.4265 18.5201 38.253L18.8508 38.2499H33.1466Z"
            fill="currentColor"
          />
        </svg>
        <Headline level={5} align="center">
          Bitte melde Dich an, um Deine Stimme zu bestätigen.
        </Headline>
        <Copy size="medium" align="center">
          Damit garantieren wir, dass die Abstimmung fair verläuft.
        </Copy>
        <Stack align="center" gap="xs">
          <Button label="Jetzt anmelden" onClick={() => triggerAuth()} />
          <Button
            variant="subtle"
            label="Nicht abstimmen"
            onClick={() => onClose()}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};
