import { FC } from 'react';
import { Headline } from '../../components/Headline';
import { Copy } from '../../components/Copy';
import { Stack } from '../../components/Stack';
import { Modal } from '../../components/Modal';
import { CommonStepProps } from './types';
import { Button } from '../../components/Button';
import { isIconName } from '../../components/Icon';
import { useSteps } from '../../utils/steps';
import { RaffleStep } from '@data/eve-sdk-web';
import { getImageUrl } from '../../utils/images';
import { Transition } from '../../components/Transition';
import { RichText } from '../../components/RichText';

export type RaffleProps = CommonStepProps & {
  step: RaffleStep;
};

export const Raffle: FC<RaffleProps> = ({ group, step, onEnded }) => {
  const { goToNextStep } = useSteps();
  const actionTransition = (step.transitions ?? []).find(
    (transition) => transition.type === 'actionOverlay',
  );

  return (
    <Modal
      id={`step-${group.id}-${step.id}`}
      open
      floating
      variant="medium"
      onClose={() => onEnded()}
      coverImage={
        step.image?.url ? getImageUrl(step.image.url, 'teaser') : undefined
      }>
      <Stack>
        <Headline level={4} align="center">
          {step.title}
        </Headline>
        {step.copy ? <Copy align="center">{step.copy}</Copy> : null}
        {step.legalText ? (
          <Copy align="center">
            <RichText text={step.legalText} />
          </Copy>
        ) : null}
        {step.legalLinks.map((link) => (
          <Copy key={link.label} align="center">
            <a href={link.url} target="_blank" rel="nofollow noreferrer">
              {link.label}
            </a>
          </Copy>
        ))}
        <Transition start={actionTransition?.start} end={actionTransition?.end}>
          <Stack gap="xs" align="center">
            {step.cta ? (
              <Button
                onClick={() => goToNextStep()}
                icon={isIconName(step.cta.icon) ? step.cta.icon : undefined}
                label={step.cta.label}
              />
            ) : null}
            {step.ctaCancel ? (
              <Button
                variant="subtle"
                onClick={() => onEnded()}
                icon={
                  isIconName(step.ctaCancel.icon)
                    ? step.ctaCancel.icon
                    : undefined
                }
                label={step.ctaCancel.label}
              />
            ) : null}
          </Stack>
        </Transition>
      </Stack>
    </Modal>
  );
};
