import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  ContentResource,
  PlayerEvent,
  PlayerEventPayload,
  SourceFetcherToken,
} from '@p7s1/oasis-types';
import { useOasisPlayer } from '../utils/oasis';
import { useConfig } from '../utils/config';
import { somMarketingActive, somMarketingInactive } from '../utils/player';

import styles from './OasisPlayer.module.scss';

export type OasisPlayerProps = {
  content: Partial<ContentResource>;
  playerConfigId?: string;
  startPosition?: number;
  seamless?: boolean;
  ads?: boolean;
  onAdRollStart?: () => void;
  onAdRollEnd?: () => void;
  onPlaylistIndexChanged?: (index: number, length: number) => void;
  onPlaylistEnd?: () => void;
  onPositionChange?: (position: number) => unknown;
};

export const OasisPlayer: FC<OasisPlayerProps> = ({
  content,
  seamless,
  ads,
  playerConfigId,
  startPosition,
  onAdRollStart,
  onAdRollEnd,
  onPlaylistIndexChanged,
  onPlaylistEnd,
  onPositionChange,
}) => {
  const { miraToken } = useConfig();
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null,
  );
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [adIsPlaying, setAdIsPlaying] = useState(false);

  const contentResource: ContentResource = useMemo(
    () => ({
      id: content?.id,
      contentType: content.contentType,
      somMarketing: ads ? somMarketingActive : somMarketingInactive,
    }),
    [content, ads],
  );

  const playerConfig = useMemo(
    () =>
      element && videoElement
        ? {
            id: playerConfigId ?? 'prosieben-de-interaction',
            videoElement,
            element,
            seamless,
          }
        : null,
    [element, playerConfigId, videoElement, seamless],
  );

  const player = useOasisPlayer(playerConfig, contentResource);

  const listener = useCallback(
    ({ position }: PlayerEventPayload[PlayerEvent.POSITION_CHANGE]) => {
      onPositionChange?.(position);
    },
    [onPositionChange],
  );

  useEffect(() => {
    if (player && startPosition && startPosition > 0) {
      player.seekTo(startPosition / 1000);
    }
  }, [player, startPosition]);

  useEffect(() => {
    if (player) {
      player.on(PlayerEvent.POSITION_CHANGE, listener);
      return () => {
        player.off(PlayerEvent.POSITION_CHANGE, listener);
      };
    }
  }, [player, listener]);

  useEffect(() => {
    if (miraToken && player) {
      player?.setToken(SourceFetcherToken.mira, miraToken);
    }
  }, [player, miraToken]);

  useEffect(() => {
    const handler = () => {
      setAdIsPlaying(true);
    };
    player?.on(PlayerEvent.AD_STARTED, handler);

    return () => player?.off(PlayerEvent.AD_STARTED, handler);
  }, [player]);

  useEffect(() => {
    const handler = () => {
      onAdRollStart?.();
    };
    player?.on(PlayerEvent.AD_ROLL_STARTED, handler);

    return () => player?.off(PlayerEvent.AD_ROLL_STARTED, handler);
  }, [onAdRollStart, player]);

  useEffect(() => {
    const handler = () => {
      onAdRollEnd?.();
    };
    player?.on(PlayerEvent.AD_ROLL_FINISHED, handler);

    return () => player?.off(PlayerEvent.AD_ROLL_FINISHED, handler);
  }, [onAdRollEnd, player]);

  useEffect(() => {
    const handler = ({
      playlistIndex,
      playlistLength,
    }: Record<string, number>) => {
      onPlaylistIndexChanged?.(playlistIndex, playlistLength);
    };
    player?.on(PlayerEvent.PLAYLIST_INDEX_CHANGED, handler);

    return () => player?.off(PlayerEvent.PLAYLIST_INDEX_CHANGED, handler);
  }, [onPlaylistIndexChanged, player]);

  useEffect(() => {
    const handler = () => {
      onPlaylistEnd?.();
    };
    player?.on(PlayerEvent.PLAYLIST_END, handler);

    return () => player?.off(PlayerEvent.PLAYLIST_END, handler);
  }, [onPlaylistEnd, player]);

  useEffect(() => {
    const handler = () => {
      setAdIsPlaying(false);
    };
    player?.on(PlayerEvent.PLAYING, handler);

    return () => player?.off(PlayerEvent.PLAYING, handler);
  }, [player]);

  return (
    <div
      ref={setElement}
      className={classNames(styles.player, {
        [styles['player--ad']]: adIsPlaying,
        [styles['player--seamless']]: seamless,
      })}
      data-content-id={contentResource.id}>
      <video ref={setVideoElement} className={styles.player__element} />
    </div>
  );
};
