// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OasisPlayer_player__RK-V9{position:relative;width:100%;height:100%}.OasisPlayer_player__element__3KIhF{width:100%;height:100%}.OasisPlayer_player__element__3KIhF video{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:auto;height:100%}.OasisPlayer_player--seamless__36cJC .OasisPlayer_player__element__3KIhF{object-fit:cover}", "",{"version":3,"sources":["webpack://src/components/OasisPlayer.module.scss"],"names":[],"mappings":"AAAA,2BACE,iBAAA,CACA,UAAA,CACA,WAAA,CAGF,oCACE,UAAA,CACA,WAAA,CAEA,0CACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,UAAA,CACA,WAAA,CAKF,yEACE,gBAAA","sourcesContent":[".player {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.player__element {\n  width: 100%;\n  height: 100%;\n\n  video {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: auto;\n    height: 100%;\n  }\n}\n\n.player--seamless {\n  .player__element {\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"player": "OasisPlayer_player__RK-V9",
	"player__element": "OasisPlayer_player__element__3KIhF",
	"player--seamless": "OasisPlayer_player--seamless__36cJC"
};
export default ___CSS_LOADER_EXPORT___;
