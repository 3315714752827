import { FC, useEffect, useState } from 'react';
import { Headline } from '../../components/Headline';
import { Copy } from '../../components/Copy';
import { Stack } from '../../components/Stack';
import { Modal } from '../../components/Modal';
import { CommonStepProps } from './types';
import { Button } from '../../components/Button';
import { isIconName } from '../../components/Icon';
import { useSteps } from '../../utils/steps';
import { BattleDimension, BattleStep, vote } from '@data/eve-sdk-web';
import { Transition } from '../../components/Transition';
import { DimensionPicker } from '../../components/DimensionPicker';
import { AuthWall } from '../AuthWall';
import { useConfig } from '../../utils/config';
import { fireConfetti } from '../../utils/confetti';
import { useToggles } from '../../utils/toggles';
import { supportsUnauthenticatedInteraction } from '../../utils/ui';

export type BattleProps = CommonStepProps & {
  step: BattleStep;
};

export const Battle: FC<BattleProps> = ({ group, step, onEnded }) => {
  const { context, miraToken } = useConfig();
  const { isEnabled } = useToggles();
  const { goToNextStep } = useSteps();
  const actionTransition = (step.transitions ?? []).find(
    (transition) => transition.type === 'actionOverlay',
  );
  const [showAuthWall, setShowAuthWall] = useState<boolean>(false);
  const [selectedDimension, setSelectedDimension] = useState<
    BattleDimension | undefined
  >(step.dimensions.find((dimension) => dimension.userVote));

  useEffect(() => {
    setSelectedDimension(
      step.dimensions.find((dimension) => dimension.userVote),
    );
  }, [step]);

  return (
    <>
      <Modal
        id={`step-${group.id}-${step.id}`}
        open
        floating
        variant="medium"
        onClose={() => onEnded()}
        footer={
          step.cta ? (
            <Transition
              start={actionTransition?.start}
              end={actionTransition?.end}>
              <Button
                onClick={async () => {
                  if (selectedDimension) {
                    if (miraToken || supportsUnauthenticatedInteraction(step)) {
                      await vote(
                        context,
                        step.interactionId,
                        selectedDimension.id,
                        1,
                      );
                      if (isEnabled('confetti')) {
                        fireConfetti();
                      }
                      goToNextStep();
                    } else {
                      setShowAuthWall(true);
                    }
                  }
                }}
                icon={
                  step.cta && isIconName(step.cta.icon)
                    ? step.cta.icon
                    : undefined
                }
                label={step.cta?.label ?? ''}
                disabled={!selectedDimension}
              />
            </Transition>
          ) : undefined
        }>
        <Stack>
          <Headline level={4} align="center">
            {step.title}
          </Headline>
          {step.copy ? <Copy align="center">{step.copy}</Copy> : null}
          <DimensionPicker
            divider={step.dimensionsSeparatorIcon ?? undefined}
            variant={step.layoutType === 'horizontal' ? 'lane' : 'list'}
            items={step.dimensions.map((dimension) => ({
              id: dimension.id,
              label: dimension.label ?? '',
              coverImage: dimension.image,
            }))}
            selected={selectedDimension?.id}
            onSelect={(item) => {
              if (selectedDimension?.id === item.id) {
                setSelectedDimension(undefined);
              } else {
                setSelectedDimension(
                  step.dimensions.find((dimension) => dimension.id === item.id),
                );
              }
            }}
          />
        </Stack>
      </Modal>
      {showAuthWall ? (
        <AuthWall onClose={() => setShowAuthWall(false)} />
      ) : null}
    </>
  );
};
