import { FC } from 'react';
import { Headline } from '../../components/Headline';
import { Copy } from '../../components/Copy';
import { Stack } from '../../components/Stack';
import { Modal } from '../../components/Modal';
import { CommonStepProps } from './types';
import { Button } from '../../components/Button';
import { isIconName } from '../../components/Icon';
import { useSteps } from '../../utils/steps';
import { InfoStep } from '@data/eve-sdk-web';
import { getImageUrl } from '../../utils/images';

export type InfoProps = CommonStepProps & {
  step: InfoStep;
};

export const Info: FC<InfoProps> = ({ group, step, onEnded }) => {
  const { goToNextStep } = useSteps();

  return (
    <Modal
      id={`step-${group.id}-${step.id}`}
      open
      floating
      variant="medium"
      onClose={() => onEnded()}
      backgroundVideo={
        step.backgroundVideo
          ? {
              video: step.backgroundVideo,
              onEnded: () => goToNextStep(),
            }
          : undefined
      }
      coverImage={
        step.image?.url ? getImageUrl(step.image.url, 'teaser') : undefined
      }
      footer={
        step.cta ? (
          <Button
            onClick={() => goToNextStep()}
            icon={isIconName(step.cta.icon) ? step.cta.icon : undefined}
            label={step.cta.label}
          />
        ) : undefined
      }>
      {step.title || step.copy ? (
        <Stack>
          {step.title ? (
            <Headline level={4} align="center">
              {step.title}
            </Headline>
          ) : null}
          {step.copy ? <Copy align="center">{step.copy}</Copy> : null}
        </Stack>
      ) : null}
    </Modal>
  );
};
