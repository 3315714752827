import { FC } from 'react';
import classNames from 'classnames';

import styles from './Cluster.module.scss';

export type ClusterGap =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl';

export type ClusterProps = {
  gap?: ClusterGap;
  x?: 'start' | 'center' | 'end';
  y?: 'start' | 'center' | 'end';
  responsive?: boolean;
  wrap?: boolean;
  stretched?: boolean;
};

export const Cluster: FC<ClusterProps> = ({
  gap = 'md',
  y = 'start',
  x = 'start',
  responsive,
  wrap,
  stretched,
  children,
}) => {
  return (
    <div
      className={classNames(
        styles.cluster,
        styles[`cluster--${gap}`],
        styles[`cluster--y-${y}`],
        styles[`cluster--x-${x}`],
        {
          [styles['cluster--responsive']]: responsive,
          [styles['cluster--wrap']]: wrap,
          [styles['cluster--stretched']]: stretched,
        },
      )}>
      {children}
    </div>
  );
};
