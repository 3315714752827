import { CreateTypes, Options } from 'canvas-confetti';

let instance: CreateTypes | null = null;

export const getInstance = (): CreateTypes | null => instance;

export const setInstance = (newInstance: CreateTypes | null): void => {
  instance = newInstance;
};

export const fireConfetti = (): void => {
  const confetti = getInstance();

  const makeShot = async (particleRatio: number, opts: Options) => {
    if (confetti) {
      await confetti({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
    }
  };

  makeShot(0.25, {
    spread: 26,
    startVelocity: 55,
  });

  makeShot(0.2, {
    spread: 60,
  });

  makeShot(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });

  makeShot(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });

  makeShot(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};
