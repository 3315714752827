import { formatRelative as formatRelativeHandler } from 'date-fns';
import { de } from 'date-fns/locale';

export const formatRelative = (
  date: number | Date,
  baseDate: number | Date,
): string =>
  formatRelativeHandler(date, baseDate, {
    locale: de,
  });
