import { FC } from 'react';
import { Bolt, Dislike, IconProps, Like, Send } from './Icon';

export type EveIconProps = {
  name: string;
} & IconProps;

export const EveIcon: FC<EveIconProps> = ({ name, ...props }) => {
  switch (name) {
    case 'Bolt':
      return <Bolt {...props} />;
    case 'Send':
      return <Send {...props} />;
    case 'Like':
      return <Like {...props} />;
    case 'Dislike':
      return <Dislike {...props} />;
    default:
      return <>MISSING ICON »{name}«</>;
  }
};
