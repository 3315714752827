import { createContext, FC, useContext } from 'react';
import { createPortal } from 'react-dom';

const getPortalId = (id: string): string => `eve-portal-${id}`;

export const PortalContext = createContext<string>('default');

export const usePortal = (): HTMLElement =>
  document.getElementById(getPortalId(useContext(PortalContext))) ??
  document.body;

export type PortalProviderProps = {
  id: string;
};

export const PortalProvider: FC<PortalProviderProps> = ({ id, children }) => {
  return (
    <PortalContext.Provider value={id}>
      {children}
      <div id={getPortalId(id)} />
    </PortalContext.Provider>
  );
};

export const Portal: FC = ({ children }) => {
  const portal = usePortal();
  return <>{portal ? createPortal(children, portal) : children}</>;
};
