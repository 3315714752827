import { FC, Fragment } from 'react';
import { Headline } from '../../components/Headline';
import { Copy } from '../../components/Copy';
import { Modal } from '../../components/Modal';
import { CommonResultsViewProps } from './types';
import { Stack } from '../../components/Stack';

import styles from './ResultsList.module.scss';

export const ResultsList: FC<CommonResultsViewProps> = ({
  group,
  step,
  footer,
  onEnded,
}) => {
  return (
    <Modal
      id={`step-${group.id}-${step.id}`}
      open
      floating
      seamless
      variant="medium"
      onClose={() => onEnded()}
      footer={footer}>
      <div className={styles['results-list']}>
        <div className={styles['results-list__header']}>
          <Stack>
            <Headline level={4} align="center">
              {step.title}
            </Headline>
            {step.copy ? <Copy align="center">{step.copy}</Copy> : null}
          </Stack>
        </div>
        <div className={styles['results-list__tiles']}>
          {step.dimensions.map((dimension, index) => (
            <Fragment key={dimension.id}>
              {index > 0 ? (
                <div className={styles['results-list__divider']} />
              ) : null}
              <div className={styles['results-list__item']}>
                <div className={styles['results-list__details']}>
                  <div className={styles['results-list__label']}>
                    {dimension.label}
                  </div>
                  <div className={styles['results-list__share']}>
                    {dimension.result.label}
                  </div>
                </div>
                <div className={styles['results-list__progress']}>
                  <div
                    className={styles['results-list__progress-bar']}
                    style={{
                      width: `${dimension.result.percentage}%`,
                    }}
                  />
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </Modal>
  );
};
