// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spacer_spacer--xxs__V2_vA{width:var(--ds-space-small-xxs)}.Spacer_spacer--xs__2l5pI{width:var(--ds-space-small-xs)}.Spacer_spacer--sm__S96gR{width:var(--ds-space-small-sm)}.Spacer_spacer--md__2Pp0i{width:var(--ds-space-small-md)}.Spacer_spacer--lg__2aXD_{width:var(--ds-space-small-lg)}.Spacer_spacer--xl__1-R4X{width:var(--ds-space-small-xl)}.Spacer_spacer--2xl__3RP0C{width:var(--ds-space-small-2xl)}.Spacer_spacer--3xl__3pPnm{width:var(--ds-space-small-3xl)}.Spacer_spacer--fill__fV5Hz{flex:1 1 auto}", "",{"version":3,"sources":["webpack://src/components/Spacer.module.scss"],"names":[],"mappings":"AAAA,2BACE,+BAAA,CAGF,0BACE,8BAAA,CAGF,0BACE,8BAAA,CAGF,0BACE,8BAAA,CAGF,0BACE,8BAAA,CAGF,0BACE,8BAAA,CAGF,2BACE,+BAAA,CAGF,2BACE,+BAAA,CAGF,4BACE,aAAA","sourcesContent":[".spacer--xxs {\n  width: var(--ds-space-small-xxs);\n}\n\n.spacer--xs {\n  width: var(--ds-space-small-xs);\n}\n\n.spacer--sm {\n  width: var(--ds-space-small-sm);\n}\n\n.spacer--md {\n  width: var(--ds-space-small-md);\n}\n\n.spacer--lg {\n  width: var(--ds-space-small-lg);\n}\n\n.spacer--xl {\n  width: var(--ds-space-small-xl);\n}\n\n.spacer--2xl {\n  width: var(--ds-space-small-2xl);\n}\n\n.spacer--3xl {\n  width: var(--ds-space-small-3xl);\n}\n\n.spacer--fill {\n  flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spacer--xxs": "Spacer_spacer--xxs__V2_vA",
	"spacer--xs": "Spacer_spacer--xs__2l5pI",
	"spacer--sm": "Spacer_spacer--sm__S96gR",
	"spacer--md": "Spacer_spacer--md__2Pp0i",
	"spacer--lg": "Spacer_spacer--lg__2aXD_",
	"spacer--xl": "Spacer_spacer--xl__1-R4X",
	"spacer--2xl": "Spacer_spacer--2xl__3RP0C",
	"spacer--3xl": "Spacer_spacer--3xl__3pPnm",
	"spacer--fill": "Spacer_spacer--fill__fV5Hz"
};
export default ___CSS_LOADER_EXPORT___;
