// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_app__2ziFi{position:relative;margin:0 auto}.App_app__confetti__1NLAr{position:fixed;top:0px;left:0px;z-index:123456;width:100%;height:100%;pointer-events:none}", "",{"version":3,"sources":["webpack://src/App.module.scss"],"names":[],"mappings":"AAEA,gBACE,iBAAA,CACA,aAAA,CAGF,0BACE,cAAA,CACA,OAAA,CACA,QAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,mBAAA","sourcesContent":["@import './scss/dimensions';\n\n.app {\n  position: relative;\n  margin: 0 auto;\n}\n\n.app__confetti {\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  z-index: 123456;\n  width: 100%;\n  height: 100%;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "App_app__2ziFi",
	"app__confetti": "App_app__confetti__1NLAr"
};
export default ___CSS_LOADER_EXPORT___;
