import { FC, useRef, useState } from 'react';
import { useClickOutside } from '../utils/hooks';

import styles from './ContextMenu.module.scss';

export type ContextMenuItem = {
  id: string;
  label: string;
  onClick: () => void;
};

export type ContextMenuProps = {
  items: ContextMenuItem[];
};

export const ContextMenu: FC<ContextMenuProps> = ({ items, children }) => {
  const trigger = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useClickOutside(trigger, () => setIsOpen(false));

  return (
    <div className={styles['context-menu']} ref={trigger}>
      <div
        className={styles['context-menu__trigger']}
        onClick={() => setIsOpen(!isOpen)}>
        {children}
      </div>
      {isOpen ? (
        <div className={styles['context-menu__items']}>
          {items.map(({ id, label, onClick }) => (
            <div key={id} className={styles['context-menu__item']}>
              <button
                className={styles['context-menu__action']}
                onClick={() => {
                  onClick?.();
                  setIsOpen(false);
                }}>
                {label}
              </button>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
