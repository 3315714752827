import { FC } from 'react';
import classNames from 'classnames';
import { getIcon, IconName } from './Icon';

import styles from './Button.module.scss';

export type ButtonVariant = 'prominent' | 'subtle' | 'ghost';

export type ButtonSize = 'default' | 'big';

export type ButtonProps = {
  label?: string;
  icon?: IconName;
  onClick?: () => void;
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  selected?: boolean;
  colors?: {
    background: string;
    color: string;
  };
};

export const Button: FC<ButtonProps> = ({
  label,
  icon,
  variant = 'prominent',
  size = 'default',
  disabled,
  selected,
  colors,
  onClick,
}) => {
  const IconComponent = icon && getIcon(icon);

  return (
    <button
      style={
        colors
          ? {
              '--button-background': colors.background,
              '--button-color': colors.color,
            }
          : undefined
      }
      className={classNames(
        styles.button,
        styles[`button--${variant}`],
        styles[`button--${size}`],
        styles[`button--${label ? 'with' : 'without'}-label`],
        styles[`button--${icon ? 'with' : 'without'}-icon`],
        {
          [styles['button--disabled']]: disabled,
          [styles['button--selected']]: selected,
        },
      )}
      onClick={() => (!disabled ? onClick?.() : undefined)}>
      {IconComponent ? (
        <span className={styles['button__icon']}>
          <IconComponent size={16} />
        </span>
      ) : null}
      {label ? <span className={styles['button__label']}>{label}</span> : null}
    </button>
  );
};
