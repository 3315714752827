import { FC, useMemo, useState } from 'react';
import { fromUnixTime } from 'date-fns';
import {
  Comment as CommentType,
  deleteComment,
  Flow,
  postCommentReaction,
  reportComment,
} from '@data/eve-sdk-web';
import { nanoid } from 'nanoid';
import { Avatar } from '../components/Avatar';
import { getImageUrl } from '../utils/images';
import { Heart, HeartInactive, More } from '../components/Icon';
import { useConfig } from '../utils/config';
import { formatRelative } from '../utils/dates';
import { ContextMenu, ContextMenuItem } from '../components/ContextMenu';
import { useNotification } from '../components/Notifications';
import { Stack } from '../components/Stack';
import { Headline } from '../components/Headline';
import { Copy } from '../components/Copy';
import { Button } from '../components/Button';
import { Modal } from '../components/Modal';

import styles from './Comment.module.scss';

export type CommentProps = {
  comment: CommentType;
  interaction: Flow;
};

export const Comment: FC<CommentProps> = ({ comment, interaction }) => {
  const { context } = useConfig();
  const notification = useNotification();
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [showReportConfirmation, setShowReportConfirmation] =
    useState<boolean>(false);

  const actionItems: ContextMenuItem[] = useMemo(() => {
    if (comment.owned) {
      return [
        {
          id: 'delete',
          label: 'Kommentar löschen',
          onClick: () => setShowDeleteConfirmation(true),
        },
      ];
    }

    return [
      {
        id: 'report',
        label: 'Kommentar melden',
        onClick: () => setShowReportConfirmation(true),
      },
    ];
  }, [comment.owned]);

  return (
    <>
      <div className={styles['comment']}>
        <div className={styles['comment__author']}>
          <span className={styles['comment__avatar']}>
            <Avatar
              size="small"
              image={
                comment.author.image
                  ? getImageUrl(comment.author.image.url, 'cover')
                  : undefined
              }
              name={comment.author.name}
            />
          </span>
          <span className={styles['comment__name']}>{comment.author.name}</span>{' '}
          ·{' '}
          <span className={styles['comment__date']}>
            {formatRelative(fromUnixTime(comment.createdAt), new Date())}
          </span>
        </div>
        <div className={styles['comment__message']}>{comment.message}</div>
        <div className={styles['comment__footer']}>
          <div className={styles['comment__likes']}>
            <button
              className={styles['comment__like']}
              onClick={async () => {
                await postCommentReaction(
                  context,
                  interaction.id,
                  comment.id,
                  comment.reaction === 'likes' ? null : 'likes',
                );
              }}>
              {comment.reaction === 'likes' ? (
                <Heart size={16} />
              ) : (
                <HeartInactive size={16} />
              )}
            </button>
            <div className={styles['comment__count']}>{comment.score}</div>
          </div>
          <div className={styles['comment__actions']}>
            <ContextMenu items={actionItems}>
              <div className={styles['comment__more']}>
                <More size={16} />
              </div>
            </ContextMenu>
          </div>
        </div>
      </div>
      {showDeleteConfirmation ? (
        <Modal
          id={`delete-confirmation-${comment.id}`}
          variant="small"
          align="center"
          open
          floating
          onClose={() => setShowDeleteConfirmation(false)}>
          <Stack>
            <Headline level={5} align="center">
              Möchtest du deinen Kommentar wirklich löschen?
            </Headline>
            <Copy size="medium" align="center">
              Einmal gelöschte Kommentare kannst du nicht wiederherstellen.
            </Copy>
            <Stack align="center" gap="xs">
              <Button
                label="Ja, Kommentar löschen"
                onClick={async () => {
                  await deleteComment(context, interaction.id, comment.id);
                  notification({
                    id: nanoid(),
                    icon: 'Checkmark',
                    title: 'Dein Kommentar wurde gelöscht',
                  });
                  setShowDeleteConfirmation(false);
                }}
              />
              <Button
                variant="subtle"
                label="Nein, doch nicht"
                onClick={() => setShowDeleteConfirmation(false)}
              />
            </Stack>
          </Stack>
        </Modal>
      ) : null}
      {showReportConfirmation ? (
        <Modal
          id={`report-confirmation-${comment.id}`}
          variant="small"
          align="center"
          open
          floating
          onClose={() => setShowReportConfirmation(false)}>
          <Stack>
            <Headline level={5} align="center">
              Möchtest du den Kommentar von {comment.author.name} wirklich
              melden?
            </Headline>
            <Copy size="medium" align="center">
              Der Kommentar wird dann von uns geprüft und entfernt, wenn er
              gegen die Richtlinien verstößt.
            </Copy>
            <Copy size="medium" align="center">
              Ach ja: Und danke für deine Mithilfe!
            </Copy>
            <Stack align="center" gap="xs">
              <Button
                label="Ja, Kommentar melden"
                onClick={async () => {
                  await reportComment(context, interaction.id, comment.id);
                  notification({
                    id: nanoid(),
                    icon: 'Checkmark',
                    title: 'Kommentar gemeldet. Danke!',
                  });
                  setShowReportConfirmation(false);
                }}
              />
              <Button
                variant="subtle"
                label="Nein, doch nicht"
                onClick={() => setShowReportConfirmation(false)}
              />
            </Stack>
          </Stack>
        </Modal>
      ) : null}
    </>
  );
};
