import React from 'react';
import ReactDOM from 'react-dom';
import { Config } from '@data/eve-sdk-web-ui-types';
import {
  ConfigContext,
  defaultConfig,
  deriveConfigFromIntegration,
} from './utils/config';
import { StyleSheets } from './utils/StyleSheets';
import {
  getDesignSystemEnvironment,
  getThemeConfigForContext,
  getThemeNameWithColorScheme,
} from './utils/ui';
import { loadFonts } from './utils/themes';
import { error } from './utils/logging';
import { App } from './App';

import './scss/base.scss';

const getMountPoint = (): HTMLDivElement => {
  const mountPoint = document.createElement('div');
  mountPoint.className = 'eve-interactions';
  return mountPoint;
};

const isJson = (input: string | Record<string, unknown>): boolean => {
  let item = typeof input !== 'string' ? JSON.stringify(input) : input;
  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }
  if (typeof item === 'object' && item !== null) {
    return true;
  }
  return false;
};

const convertAttributeValue = (
  value: string | Record<string, string | number | boolean>,
):
  | string
  | number
  | boolean
  | Record<string, string | number | boolean>
  | undefined => {
  if (typeof value === 'object' && value !== null) {
    return value;
  } else if (value === 'true' || value === 'false') {
    return value === 'true';
  } else if (value === 'null' || value === 'undefined') {
    return undefined;
  } else if (/^[0-9-]+$/.test(value)) {
    return parseInt(value, 10);
  } else if (isJson(value)) {
    try {
      return JSON.parse(value);
    } catch {
      /* NOOP */
    }
  }
  return value;
};

class EveIneractionsElement extends HTMLElement {
  private mountPoint: HTMLDivElement;

  constructor() {
    super();
    this.mountPoint = getMountPoint();
  }

  mountMountPoint() {
    if (this.mountPoint.parentNode !== this) {
      this.appendChild(this.mountPoint);
    }
  }

  ensureMountedMountPoint() {
    this.mountMountPoint();
    new MutationObserver(() => {
      this.mountMountPoint();
    }).observe(this, {
      childList: true,
      attributes: true,
    });
  }

  renderReact() {
    const derivedConfig = deriveConfigFromIntegration();
    const config = Object.entries(defaultConfig).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: convertAttributeValue(
          // Passed attributes
          this.getAttribute(key) ??
            this.getAttribute(key.toLowerCase()) ??
            // Derived configuration
            derivedConfig[key as keyof Config] ??
            // Default value
            value,
        ),
      }),
      {} as Config,
    );

    if (!config.context) {
      return;
    }

    const { theme, colors } = getThemeConfigForContext(config.context);
    config.theme = getThemeNameWithColorScheme(config.theme || theme);
    config.colors = config.colors || colors.join('|');

    loadFonts({
      env: getDesignSystemEnvironment(config.env),
    });

    ReactDOM.render(
      <React.StrictMode>
        <ConfigContext.Provider value={config}>
          <StyleSheets
            env={getDesignSystemEnvironment(config.env)}
            themes={[config.theme]}
          />
          <App />
        </ConfigContext.Provider>
      </React.StrictMode>,
      this.mountPoint,
    );

    this.ensureMountedMountPoint();
  }

  async applyPolyfills() {
    if (!('scrollBehavior' in document.documentElement.style)) {
      await import('scroll-behavior-polyfill');
    }
  }

  connectedCallback() {
    this.renderReact();
    this.applyPolyfills();
  }

  static get observedAttributes() {
    return [
      ...Object.keys(defaultConfig),
      ...Object.keys(defaultConfig).map((key) => key.toLowerCase()),
    ];
  }

  attributeChangedCallback() {
    this.renderReact();
  }
}

customElements.define('eve-interactions', EveIneractionsElement);

/** @deprecated */
class EveVotingsElement extends EveIneractionsElement {
  constructor() {
    super();
    error('<eve-votings> is deprecated, please migrate to <eve-interactions>');
  }
}

customElements.define('eve-votings', EveVotingsElement);
