import { FC } from 'react';
import classNames from 'classnames';
import { EveImage, EveVideo } from '@data/eve-sdk-web';
import { Video } from './Video';
import { getImageUrl, getSdkDimensionImage } from '../utils/images';
import { useConfig } from '../utils/config';

import styles from './DimensionPicker.module.scss';

export type DimensionPickerItem = {
  id: string;
  label: string;
  coverImage?: EveImage | null;
  backgroundVideo?: EveVideo;
};

export type DimensionPickerVariant = 'list' | 'lane';

export type DimensionPickerProps = {
  items: DimensionPickerItem[];
  selected?: string;
  variant?: DimensionPickerVariant;
  divider?: string;
  onSelect: (newItem: DimensionPickerItem) => void;
};

export const DimensionPicker: FC<DimensionPickerProps> = ({
  items,
  selected,
  variant = 'list',
  divider,
  onSelect,
}) => {
  const { context, env } = useConfig();

  return (
    <div
      className={classNames(
        styles['dimension-picker'],
        styles[`dimension-picker--${variant}`],
        {
          [styles['dimension-picker--divided']]: divider,
          [styles['dimension-picker--blank']]: selected === undefined,
        },
      )}>
      {items.map((item) => {
        const dimensionImage = getSdkDimensionImage(env, context, item.id);

        return (
          <div
            key={item.id}
            className={classNames(styles['dimension-picker__item'], {
              [styles['dimension-picker__item--image']]:
                dimensionImage || item.coverImage,
              [styles['dimension-picker__item--active']]: selected === item.id,
            })}
            onClick={() => onSelect(item)}>
            {item.backgroundVideo ? (
              <div className={styles['dimension-picker__video']}>
                <Video video={item.backgroundVideo} autoPlay seamless />
              </div>
            ) : null}
            <div className={styles['dimension-picker__content']}>
              <div className={styles['dimension-picker__label']}>
                {item.label}
              </div>
            </div>
            {dimensionImage || item.coverImage ? (
              <img
                src={
                  dimensionImage
                    ? dimensionImage
                    : item.coverImage
                    ? getImageUrl(item.coverImage.url, 'square')
                    : undefined
                }
                alt={item.label}
                className={styles['dimension-picker__visual']}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
