import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { setAuth, setup, subscribe, FlowGroup } from '@data/eve-sdk-web';
import classNames from 'classnames';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useConfig } from './utils/config';
import { ThemeProvider } from './utils/themes';
import { log } from './utils/logging';
import { Lane } from './fragments/Lane';
import { Floating } from './fragments/Floating';
import { Group } from './fragments/Group';
import { PortalProvider } from './utils/portal';
import { filterGroupsByTags } from './utils/tags';
import { setInstance as setConfettiInstance } from './utils/confetti';
import { Notifications } from './components/Notifications';
import { generateGuestToken } from './utils/token';

import styles from './App.module.scss';

export const App: FC = () => {
  const {
    env,
    miraToken,
    context,
    variant,
    theme: passedTheme,
    tags,
  } = useConfig();
  const ref = useRef<HTMLDivElement>(null);
  const [theme, setTheme] = useState<string>(passedTheme ?? 'default-dark');
  const [groups, setGroups] = useState<FlowGroup[]>([]);
  const [currentGroupId, setCurrentGroupId] = useState<string | undefined>(
    undefined,
  );
  const filteredGroups = useMemo(
    () => filterGroupsByTags(groups, tags ?? []),
    [groups, tags],
  );
  const currentGroup = useMemo(
    () => groups.find((group) => group.id === currentGroupId),
    [currentGroupId, groups],
  );

  const init = useCallback(async () => {
    if (context) {
      if (miraToken) {
        log('[EVE] Setup using MIRA token', env, miraToken);
        await setup(env, miraToken);
      } else {
        const guestToken = await generateGuestToken(context);
        log('[EVE] Setup using guest token', env, guestToken);
        await setup(env, guestToken);
      }
      subscribe(context, (items) => {
        log(`[EVE] Subscription callback for "${context}"`, items);
        setGroups(items);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, env]);

  useEffect(() => {
    log('[EVE] Set user', miraToken);
    if (miraToken) {
      setAuth(miraToken);
    }
  }, [miraToken]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    setTheme(passedTheme ?? 'default-dark');
  }, [passedTheme]);

  return (
    <div
      ref={ref}
      className={classNames(styles.app, styles[`app--${variant}`])}>
      <ThemeProvider theme={theme} setTheme={(newTheme) => setTheme(newTheme)}>
        <PortalProvider id={`${context}-${variant}`}>
          {variant === 'lane' ? (
            <Lane
              groups={filteredGroups}
              onShowGroup={(group) => setCurrentGroupId(group.id)}
            />
          ) : variant === 'floating' ? (
            <Floating
              groups={filteredGroups}
              onShowGroup={(group) => setCurrentGroupId(group.id)}
            />
          ) : null}
          {currentGroup ? (
            <Group
              group={currentGroup}
              onClose={() => setCurrentGroupId(undefined)}
            />
          ) : null}
          <Notifications />
        </PortalProvider>
        <ReactCanvasConfetti
          refConfetti={setConfettiInstance}
          className={styles.app__confetti}
        />
      </ThemeProvider>
    </div>
  );
};
