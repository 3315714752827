export const DIMENSION_IMAGE_MAPPING: Record<string, string> = {
  'tms-s4-ch:326719': 'TMSCH_23_IN_Bubbels.jpg',
  'tms-s4-ch:326737': 'TMSCH_23_IN_Koefferli.jpg',
  'tms-s4-ch:326746': 'TMSCH_23_IN_Reh.jpg',
  'tms-s4-ch:326739': 'TMSCH_23_IN_Ruebli.jpg',
  'tms-s4-ch:326750': 'TMSCH_23_IN_Orca.jpg',
  'tms-s4-ch:326694': 'TMSCH_23_IN_Schwan.jpg',
  'tms-s4-ch:332097': 'TMSCH_23_IN_Milch.jpg',
  'tms-s4-ch:326752': 'TMSCH_23_IN_Milch.jpg',
  'tms-s4-ch:326644': 'TMSCH_23_IN_Nessi.jpg',
};
