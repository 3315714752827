import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { on, off, emit, Handler } from '../utils/bus';
import { usePortal } from '../utils/portal';
import { getIcon, IconName } from './Icon';
import { useConfig } from '../utils/config';

import styles from './Notifications.module.scss';

export type NotificationsItem = {
  id: string;
  icon?: IconName;
  title: string;
  text?: string;
};

export const useNotification = (): ((
  notification: NotificationsItem,
) => void) => {
  const { context } = useConfig();

  return (notification: NotificationsItem): void => {
    emit(`notification:${context}`, {
      notification,
    });
  };
};

export const Notifications: FC = () => {
  const portal = usePortal();
  const { context } = useConfig();
  const [items, setItems] = useState<NotificationsItem[]>([]);

  useEffect(() => {
    const handler: Handler = ({ notification }) => {
      setItems((items) => [notification, ...items]);

      setTimeout(() => {
        setItems((items) =>
          items.filter((item) => item.id !== notification.id),
        );
      }, 5 * 1000);
    };

    on(`notification:${context}`, handler);

    return () => off(`notification:${context}`, handler);
  }, [context]);

  return createPortal(
    <div className={styles.notifications}>
      <AnimatePresence>
        {items.map(({ id, icon, title, text }) => {
          const IconComponent = icon && getIcon(icon);

          return (
            <motion.div
              key={id}
              initial={{ y: -15, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 15, opacity: 0 }}
              transition={{
                duration: 0.5,
              }}
              className={styles['notifications__item']}>
              <div className={styles['notifications__header']}>
                {IconComponent ? (
                  <div className={styles['notifications__icon']}>
                    <IconComponent size={16} />
                  </div>
                ) : null}
                <div className={styles['notifications__title']}>{title}</div>
              </div>
              {text ? (
                <div className={styles['notifications__text']}>{text}</div>
              ) : null}
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>,
    portal,
  );
};
