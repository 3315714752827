import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import {
  changeCommentCategory,
  CommentsStep,
  loadMoreComments,
  postComment,
} from '@data/eve-sdk-web';
import { Headline } from '../../components/Headline';
import { Copy } from '../../components/Copy';
import { Stack } from '../../components/Stack';
import { Modal } from '../../components/Modal';
import { CommonStepProps } from './types';
import { Comment } from '../Comment';
import { CommentEditor } from '../CommentEditor';
import { useConfig } from '../../utils/config';
import { AuthWall } from '../AuthWall';
import { Comments as CommentsIcon } from '../../components/Icon';
import { useNotification } from '../../components/Notifications';

import styles from './Comments.module.scss';

export type CommentsProps = CommonStepProps & {
  step: CommentsStep;
};

export const Comments: FC<CommentsProps> = ({
  group,
  interaction,
  step,
  onEnded,
}) => {
  const { miraToken, context } = useConfig();
  const [showAuthWall, setShowAuthWall] = useState<boolean>(!miraToken);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [categoryId, setCategoryId] = useState<string>(step.categories[0].id);
  const notification = useNotification();

  useEffect(() => {
    loadMoreComments(context, interaction.id);
  }, [categoryId, context, group, interaction.id, step.categories]);

  return (
    <>
      <Modal
        id={`step-${group.id}-${step.id}`}
        open
        floating
        seamless
        variant="medium"
        onClose={() => onEnded()}>
        <div
          className={classNames(styles['comments'], {
            [styles['comments--scrolled']]: isScrolled,
          })}>
          <div className={styles['comments__category']}>
            <select
              className={styles['comments__dropdown']}
              onChange={async (e) => {
                await changeCommentCategory(
                  context,
                  interaction.id,
                  e.target.value,
                );
                setCategoryId(e.target.value);
              }}
              defaultValue={categoryId}>
              {step.categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
          <div className={styles['comments__header']}>
            <div className={styles['comments__header-icon']}>
              <CommentsIcon size={150} />
            </div>
            <div className={styles['comments__header-content']}>
              <Stack>
                <Headline level={4} align="center">
                  {step.title}
                </Headline>
                {step.copy ? <Copy align="center">{step.copy}</Copy> : null}
              </Stack>
            </div>
          </div>
          {step.comments.length ? (
            <div
              className={styles['comments__list']}
              onScroll={(e) => {
                const target = e.target;
                if (target instanceof HTMLDivElement) {
                  setIsScrolled(target.scrollTop > 0);
                }
              }}>
              <Stack>
                {step.comments.map((comment) => (
                  <Comment
                    key={comment.id}
                    comment={comment}
                    interaction={interaction}
                  />
                ))}
              </Stack>
            </div>
          ) : null}
          <div className={styles['comments__footer']}>
            <CommentEditor
              placeholder="Deine Meinung"
              onSubmit={async (message) => {
                await postComment(context, interaction.id, message);
                notification({
                  id: nanoid(),
                  icon: 'Checkmark',
                  title: 'Kommentar abgeschickt. Danke dir!',
                });
              }}
              maxLength={step.maxLength}
            />
          </div>
        </div>
      </Modal>
      {showAuthWall ? (
        <AuthWall onClose={() => setShowAuthWall(false)} />
      ) : null}
    </>
  );
};
