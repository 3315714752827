import { FC, Fragment } from 'react';
import classNames from 'classnames';
import { Headline } from '../../components/Headline';
import { Copy } from '../../components/Copy';
import { getImageUrl } from '../../utils/images';
import { Modal } from '../../components/Modal';
import { CommonResultsViewProps } from './types';
import { getPodiumRanking } from '../../utils/podium';
import { Stack } from '../../components/Stack';

import styles from './ResultsPodium.module.scss';

export const ResultsPodium: FC<CommonResultsViewProps> = ({
  group,
  step,
  footer,
  onEnded,
}) => {
  return (
    <Modal
      id={`step-${group.id}-${step.id}`}
      open
      floating
      seamless
      variant="medium"
      onClose={() => onEnded()}
      footer={footer}>
      <div className={styles['results-podium']}>
        <div className={styles['results-podium__header']}>
          <Stack>
            <Headline level={4} align="center">
              {step.title}
            </Headline>
            {step.copy ? <Copy align="center">{step.copy}</Copy> : null}
          </Stack>
          <div className={styles['results-podium__podium']}>
            {getPodiumRanking(step.dimensions).map(
              ({ rank, dimension, fin }) => (
                <div
                  key={dimension.id}
                  className={classNames(
                    styles['results-podium__pedestal'],
                    styles[`results-podium__pedestal--${rank}`],
                  )}>
                  <div className={styles['results-podium__fin']}>{fin}</div>
                  {dimension.image ? (
                    <img
                      src={getImageUrl(dimension.image.url, 'square')}
                      alt={dimension.label ?? ''}
                      className={styles['results-podium__visual']}
                    />
                  ) : null}
                  <div className={styles['results-podium__label']}>
                    {dimension.label}
                  </div>
                  <div className={styles['results-podium__rank']}>{rank}</div>
                </div>
              ),
            )}
          </div>
        </div>
        <div className={styles['results-podium__tiles']}>
          {step.dimensions.slice(3).map((dimension, index) => (
            <Fragment key={dimension.id}>
              {index > 0 ? (
                <div className={styles['results-podium__divider']} />
              ) : null}
              <div className={styles['results-podium__item']}>
                <div className={styles['results-podium__details']}>
                  {dimension.image ? (
                    <img
                      src={getImageUrl(dimension.image.url, 'square')}
                      alt={dimension.label ?? ''}
                      className={styles['results-podium__visual']}
                    />
                  ) : null}
                  <div className={styles['results-podium__label']}>
                    {dimension.label}
                  </div>
                  <div className={styles['results-podium__rank']}>
                    {dimension.result.label}
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </Modal>
  );
};
