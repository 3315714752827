import { FC } from 'react';
import { Headline } from '../../components/Headline';
import { Stack } from '../../components/Stack';
import { Modal } from '../../components/Modal';
import { CommonStepProps } from './types';
import { Button } from '../../components/Button';
import { isIconName } from '../../components/Icon';
import { useSteps } from '../../utils/steps';
import { PlayerScoreStep } from '@data/eve-sdk-web';
import { getImageUrl } from '../../utils/images';
import { Transition } from '../../components/Transition';
import { Avatar } from '../../components/Avatar';
import { Cluster } from '../../components/Cluster';
import { motion } from 'framer-motion';
import {
  slideUpAnimation,
  growCircleAnimation,
  popInAnimation,
  slideUpAndFadeAnimation,
} from '../../utils/animations';

import styles from './PlayerScore.module.scss';

export type PlayerScoreProps = CommonStepProps & {
  step: PlayerScoreStep;
};

export const PlayerScore: FC<PlayerScoreProps> = ({ group, step, onEnded }) => {
  const { goToNextStep } = useSteps();
  const actionTransition = (step.transitions ?? []).find(
    (transition) => transition.type === 'actionOverlay',
  );

  return (
    <Modal
      id={`step-${group.id}-${step.id}`}
      open
      floating
      variant="medium"
      onClose={() => onEnded()}
      footer={
        step.cta ? (
          <Transition
            start={actionTransition?.start}
            end={actionTransition?.end}>
            <Button
              onClick={() => goToNextStep()}
              icon={isIconName(step.cta.icon) ? step.cta.icon : undefined}
              label={step.cta.label}
            />
          </Transition>
        ) : undefined
      }>
      <Stack gap="2xl">
        <Stack gap="xs">
          <motion.div
            className={styles['player-score__headline']}
            initial="initial"
            animate="animate"
            variants={slideUpAnimation(0.6)}>
            <motion.div className={styles['player-score__title']}>
              {step.title}
            </motion.div>
            <Headline level={4} align="center">
              <motion.div variants={slideUpAnimation(0.8)}>
                {step.headline}
              </motion.div>
            </Headline>
            {step.copy ? (
              <Headline level={6} align="center">
                <motion.div variants={slideUpAnimation(1)}>
                  {step.copy}
                </motion.div>
              </Headline>
            ) : null}
          </motion.div>
        </Stack>
        <Cluster gap="2xl" y="center" x="center">
          {step.players.map((player, index) => (
            <div className={styles['player-score__player']}>
              <Stack gap="xs">
                <div className={styles['player-score__avatar']}>
                  <motion.div
                    initial="initial"
                    animate="animate"
                    variants={growCircleAnimation(0.3)}
                    className={
                      styles[
                        `player-score__avatar--${
                          player.designated === true && 'winner'
                        }`
                      ]
                    }>
                    <Avatar
                      name={player.name}
                      size={player.designated === true ? 'large' : 'medium'}
                    />
                    {player.designated === true && step.spotlightDecoration && (
                      <motion.img
                        variants={growCircleAnimation(1)}
                        className={styles['player-score__crown']}
                        src={getImageUrl(
                          step.spotlightDecoration.url,
                          'square',
                        )}
                        alt=""
                      />
                    )}
                  </motion.div>
                </div>
                <div className={styles['player-score__player-name']}>
                  {index === 0 ? 'Du' : player.name}
                </div>
              </Stack>
            </div>
          ))}
        </Cluster>
        <Stack>
          <motion.div
            className={styles['player-score__scoreboard']}
            initial="initial"
            animate="animate"
            variants={popInAnimation(0.6)}>
            <div className={styles['player-score__scorebadge']}>Score</div>
            <Stack gap="lg">
              <div className={styles['player-score__score']}>
                <Cluster gap="md" x="center" y="center">
                  {step.players.map((player, index) => (
                    <>
                      {index === 1 ? (
                        <div className={styles['player-score__score-divider']}>
                          :
                        </div>
                      ) : null}
                      <div className={styles['player-score__number']}>
                        {player.score.total}
                        {player.designated === true && (
                          <motion.div
                            className={styles['player-score__plus1']}
                            variants={slideUpAndFadeAnimation(0, [0, -40], 2)}>
                            +1
                          </motion.div>
                        )}
                      </div>
                    </>
                  ))}
                </Cluster>
              </div>
              <motion.div
                className={styles['player-score__score-label']}
                initial="initial"
                animate="animate"
                variants={slideUpAnimation(1.5)}>
                {step.scoreLabel}
              </motion.div>
            </Stack>
          </motion.div>
        </Stack>
      </Stack>
    </Modal>
  );
};
