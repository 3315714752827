import { FC, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { Ad } from '@data/eve-sdk-web';
import { AnimatePresence, motion } from 'framer-motion';
import { Cross } from './Icon';
import { AdTracking } from './AdTracking';
import { lockBodyScrolling, unlockBodyScrolling } from '../utils/scroll';
import { usePortal } from '../utils/portal';
import { getImageUrl } from '../utils/images';
import { Video, VideoProps } from './Video';

import styles from './Modal.module.scss';

export type ModalVariant = 'default' | 'small' | 'medium' | 'large';

export type ModalProps = {
  id: string;
  coverImage?: string;
  backgroundVideo?: VideoProps;
  variant?: ModalVariant;
  align?: 'center';
  open?: boolean;
  floating?: boolean;
  seamless?: boolean;
  fullscreen?: boolean;
  footer?: ReactNode;
  ad?: Ad;
  onClose: () => void;
};

export const Modal: FC<ModalProps> = ({
  id,
  coverImage,
  backgroundVideo,
  variant = 'default',
  align,
  open,
  floating,
  seamless,
  fullscreen,
  children,
  footer,
  ad,
  onClose,
}) => {
  const portal = usePortal();

  useEffect(() => {
    if (open) {
      lockBodyScrolling(id);
    } else {
      unlockBodyScrolling(id);
    }
  }, [id, open]);

  useEffect(() => {
    return () => unlockBodyScrolling(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return createPortal(
    <AnimatePresence>
      {open ? (
        <div
          className={classNames(
            styles.modal,
            styles[`modal--${variant}`],
            styles[`modal--${align}`],
            {
              [styles['modal--floating']]: floating,
              [styles['modal--seamless']]: seamless,
              [styles['modal--fullscreen']]: fullscreen,
            },
            styles[`modal--${footer ? 'with' : 'without'}-footer`],
            styles[`modal--${ad ? 'with' : 'without'}-ad`],
            styles[`modal--${backgroundVideo ? 'with' : 'without'}-video`],
            styles[`modal--${children ? 'with' : 'without'}-slot`],
          )}
          data-modal={id}>
          <motion.div
            key="backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles['modal__backdrop']}
            onClick={() => onClose()}
          />
          <motion.div
            key="layer"
            initial={{ opacity: 0, scale: 0.9, y: '50%' }}
            animate={{ opacity: 1, scale: 1, y: '0%' }}
            exit={{ opacity: 0, scale: 0.9, y: '50%' }}
            className={styles['modal__layer']}>
            <div className={styles['modal__main']}>
              <div className={styles['modal__close']} onClick={() => onClose()}>
                <Cross size={20} />
              </div>
              <div
                className={styles['modal__content']}
                data-element-visibility-gate>
                {backgroundVideo ? (
                  <div className={styles['modal__video']}>
                    <Video
                      video={backgroundVideo.video}
                      autoPlay
                      onEnded={() => backgroundVideo.onEnded?.()}
                    />
                  </div>
                ) : coverImage ? (
                  <div className={styles['modal__visual']}>
                    <img
                      src={coverImage}
                      alt=""
                      className={styles['modal__image']}
                    />
                    {ad ? (
                      <AdTracking
                        group={ad.identifier}
                        label={document.location.pathname}>
                        <div className={styles['modal__ad-backdrop']} />
                        <div className={styles['modal__ad-content']}>
                          <div
                            className={styles.modal__ad}
                            onClick={() => window.open(ad.url)}>
                            <div className={styles['modal__badge']}>
                              Anzeige
                            </div>
                            <div className={styles['modal__ad-cover']}>
                              <img
                                src={getImageUrl(ad.image.url, 'teaser')}
                                alt=""
                                className={styles['modal__ad-cover-image']}
                              />
                            </div>
                          </div>
                        </div>
                      </AdTracking>
                    ) : null}
                  </div>
                ) : null}
                {children ? (
                  <div className={styles['modal__slot']}>{children}</div>
                ) : null}
              </div>
              {footer ? (
                <motion.div
                  key="footer"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className={styles['modal__footer']}>
                  {footer}
                </motion.div>
              ) : null}
            </div>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>,
    portal,
  );
};
