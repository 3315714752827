import { FlowGroup } from '@data/eve-sdk-web';

export const parseTags = (tags: string | string[]): string[] => {
  const base = (Array.isArray(tags) ? tags : [tags])
    .map((tag) => tag.split(/[|,]/))
    .flat();
  return base.map((tag) => tag.trim()).filter(Boolean);
};

export const filterGroupsByTags = (
  groups: FlowGroup[],
  tags: string | string[],
): FlowGroup[] => {
  const parsedTags = parseTags(tags);
  if (parsedTags.length > 0) {
    return groups.filter((group) =>
      group.tags.some((tag) => parsedTags.some((item) => tag.id === item)),
    );
  }
  return groups;
};
