import { isEnabled } from './toggles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const log = (...params: any[]): void => {
  if (isEnabled('logging')) {
    console.log(...params);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const error = (...params: any[]): void => {
  if (isEnabled('logging')) {
    console.error(...params);
  }
};
