import { FC } from 'react';
import { Headline } from '../../components/Headline';
import { Copy } from '../../components/Copy';
import { Stack } from '../../components/Stack';
import { Modal } from '../../components/Modal';
import { CommonStepProps } from './types';
import { Button } from '../../components/Button';
import { isIconName } from '../../components/Icon';
import { useSteps } from '../../utils/steps';
import { VideoBattleStep } from '@data/eve-sdk-web';
import { Transition } from '../../components/Transition';

export type VideoBattleProps = CommonStepProps & {
  step: VideoBattleStep;
};

export const VideoBattle: FC<VideoBattleProps> = ({ group, step, onEnded }) => {
  const { goToNextStep } = useSteps();
  const actionTransition = (step.transitions ?? []).find(
    (transition) => transition.type === 'actionOverlay',
  );

  return (
    <Modal
      id={`step-${group.id}-${step.id}`}
      open
      floating
      variant="medium"
      onClose={() => onEnded()}
      footer={
        step.cta ? (
          <Transition
            start={actionTransition?.start}
            end={actionTransition?.end}>
            <Button
              onClick={() => goToNextStep()}
              icon={isIconName(step.cta.icon) ? step.cta.icon : undefined}
              label={step.cta.label}
            />
          </Transition>
        ) : undefined
      }>
      <Stack>
        <Headline level={4} align="center">
          {step.title}
        </Headline>
        {step.copy ? <Copy align="center">{step.copy}</Copy> : null}
      </Stack>
    </Modal>
  );
};
