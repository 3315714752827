type MiraConfig = {
  pattern: RegExp;
  endpoint: string;
  clientId: string;
  type?: 'magellan' | 'publishing';
};

const IS_DEV_ENVIRONMENT =
  document.location.hostname.startsWith('integration.') ||
  document.location.hostname.startsWith('preprod.');
const MIRA_PREFIX_PREPROD = 'mira-preprod';
const MIRA_PREFIX_PROD = 'mira';
const MIRA_PREFIX_AUTO = IS_DEV_ENVIRONMENT
  ? MIRA_PREFIX_PREPROD
  : MIRA_PREFIX_PROD;

export const MIRA_KEY = '7e92d6cac838bd3150ffbeaf504b03f6';

const MIRA_CONFIGS: MiraConfig[] = [
  {
    pattern: /localhost/,
    endpoint: `${MIRA_PREFIX_AUTO}.p7s1.io`,
    clientId: '',
  },
  {
    pattern: /prosieben\.de/,
    endpoint: `${MIRA_PREFIX_AUTO}.prosieben.de`,
    clientId: 'aaeaa10821c926e6fbbe1f143d0097ce',
    type: 'magellan',
  },
  {
    pattern: /kabeleins\.de/,
    endpoint: `${MIRA_PREFIX_AUTO}.kabeleins.de`,
    clientId: 'bcf3f503b59804cfd1aa800a2fa50952',
    type: 'magellan',
  },
  {
    pattern: /sat1\.de/,
    endpoint: `${MIRA_PREFIX_AUTO}.sat1.de`,
    clientId: 'e9d756836824b6dfd739c3ffcb3cae6e',
    type: 'magellan',
  },
  {
    pattern: /sat1\.at/,
    endpoint: `${MIRA_PREFIX_AUTO}.sat1.at`,
    clientId: 'e9d756836824b6dfd739c3ffcb3cae6e',
    type: 'magellan',
  },
  {
    pattern: /sat1\.ch/,
    endpoint: `${MIRA_PREFIX_AUTO}.sat1.ch`,
    clientId: 'e9d756836824b6dfd739c3ffcb3cae6e',
    type: 'magellan',
  },
  // ZAPPN PROD
  {
    pattern: /^((www|webview-eve)\.)?zappn\.tv$/,
    endpoint: `${MIRA_PREFIX_PROD}.zappn.tv`,
    clientId: '047e58616e876481a8bbbbbdbe2e2051',
  },
  // ZAPPN QA + DEV
  {
    pattern: /\.zappn\.tv$/,
    endpoint: `${MIRA_PREFIX_PREPROD}.zappn.tv`,
    clientId: '047e58616e876481a8bbbbbdbe2e2051',
  },
  // PULS 24 PROD
  {
    pattern: /^(www\.)?puls24\.at$/,
    endpoint: `${MIRA_PREFIX_PROD}.puls24.at`,
    clientId: '22cbe6931af4ffce882f769b92052cce',
  },
  // PULS 24 QA + DEV
  {
    pattern: /\.puls24\.at$/,
    endpoint: `${MIRA_PREFIX_PREPROD}.puls24.at`,
    clientId: '22cbe6931af4ffce882f769b92052cce',
  },
];

export const getMiraConfig = (): MiraConfig | undefined =>
  MIRA_CONFIGS.find(({ pattern }) => pattern.test(document.location.hostname));
