import { FC } from 'react';
import classNames from 'classnames';

import styles from './Headline.module.scss';

export type HeadlineProps = {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  size?: 'small' | 'large';
  align?: 'left' | 'center' | 'right';
  clamped?: boolean;
};

export const Headline: FC<HeadlineProps> = ({
  level,
  size = 'small',
  align = 'left',
  clamped,
  children,
}) => {
  return (
    <div
      className={classNames(
        styles.headline,
        styles[`headline--${align}`],
        styles[`headline--${size}`],
        styles[`headline--${level}`],
        {
          [styles['headline--clamped']]: clamped,
        },
      )}>
      {children}
    </div>
  );
};
