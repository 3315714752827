import { createContext, useContext } from 'react';
import { Step } from '@data/eve-sdk-web';

export type StepsApi = {
  steps: Step[];
  step?: Step;
  hasPreviousStep: boolean;
  hasNextStep: boolean;
  goToPreviousStep: () => void;
  goToNextStep: () => void;
};

export const StepsContext = createContext<StepsApi>({
  steps: [],
  step: undefined,
  hasPreviousStep: false,
  hasNextStep: false,
  goToPreviousStep: () => undefined,
  goToNextStep: () => undefined,
});

export const useSteps = (): StepsApi => useContext(StepsContext);
