import { FC } from 'react';
import classNames from 'classnames';

import styles from './Spacer.module.scss';

export type SpacerSize =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl';

export type SpacerProps = {
  size?: SpacerSize;
};

export const Spacer: FC<SpacerProps> = ({ size = 'fill' }) => {
  return (
    <div
      className={classNames(styles.spacer, styles[`spacer--${size}`])}
      aria-hidden="true"
    />
  );
};
