import { FC } from 'react';

const DEFAULT_SIZE = 24;
const DEFAULT_COLOR = 'currentColor';
const DEFAULT_VIEW_BOX = '0 0 24 24';

export type IconProps = {
  size?: number;
  color?: string;
};

export const ArrowLeft: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M7.845 11h11.322a.833.833 0 0 1 0 1.667H7.845l3.578 3.577a.833.833 0 1 1-1.179 1.179l-5-5a.833.833 0 0 1 0-1.179l5-5a.833.833 0 1 1 1.179 1.179L7.845 11Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const ArrowRight: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M16.155 11H4.833a.833.833 0 1 0 0 1.667h11.322l-3.578 3.577a.833.833 0 0 0 1.179 1.179l5-5a.833.833 0 0 0 0-1.179l-5-5a.833.833 0 1 0-1.179 1.179L16.155 11Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Backspace: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M18.998 4H8.968c-.92 0-1.78.41-2.35 1.14l-4 5.04-.02.02a3 3 0 0 0 .02 3.62l4 5.04c.57.72 1.43 1.13 2.35 1.13h10.03c1.65 0 3-1.35 3-3V7c0-1.65-1.35-3-3-3Zm-1.79 10.29a.996.996 0 0 1-.71 1.7c-.26 0-.51-.1-.71-.29l-2.29-2.29-2.29 2.29c-.2.2-.45.29-.71.29-.26 0-.51-.1-.71-.29a.996.996 0 0 1 0-1.41l2.29-2.29-2.29-2.29a.996.996 0 1 1 1.41-1.41l2.29 2.29 2.29-2.29a.996.996 0 1 1 1.41 1.41L14.898 12l2.29 2.29h.02Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Bolt: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M6.484 24h1.972c.358 0 .701-.144.954-.397 1.26-1.266 5.523-5.503 12.466-11.946.891-.827.31-2.324-.906-2.324h-6.864L16.638 0h-1.99c-.348 0-.682.136-.93.379l-12.313 12c-.856.834-.265 2.288.931 2.288h6.68L6.484 24Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Bookmark: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M16.999 3h-10c-1.1 0-1.99.9-1.99 2L5 19.482a1 1 0 0 0 1.394.92L11.999 18l5.606 2.403a1 1 0 0 0 1.394-.92V5c0-1.1-.9-2-2-2Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Checkmark: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M21.602 5.398c.53.53.53 1.39 0 1.92L10.08 18.841c-.204.204-.457.33-.721.376l-.16.02h-.16a1.352 1.352 0 0 1-.88-.396L2.398 13.08a1.358 1.358 0 0 1 1.92-1.92l4.8 4.8L19.682 5.398c.53-.53 1.39-.53 1.92 0Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Clock: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm0 2a1 1 0 0 1 1 1v4.585l3.207 3.208a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.414 0l-3.5-3.5A1 1 0 0 1 11 12V7a1 1 0 0 1 1-1Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Comments: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <g fill={color} fillRule="nonzero">
        <path d="M8.333 1.083a8.25 8.25 0 0 0-8.25 8.25L.1 9.86a8.205 8.205 0 0 0 1 3.445l.034.057L.13 16.377l-.035.143a.917.917 0 0 0 1.195 1.016l3.014-1.005-.385-.227a8.25 8.25 0 1 0 4.414-15.22Zm0 1.834a6.417 6.417 0 1 1-3.432 11.84l-.145-.076a.917.917 0 0 0-.636-.02l-1.672.556.558-1.67a.917.917 0 0 0-.095-.781 6.417 6.417 0 0 1 5.423-9.849Z" />
        <path d="M15.667 5.972a8.25 8.25 0 0 1 7.381 11.936l-.183.342 1.005 3.016a.917.917 0 0 1-1.017 1.195l-.143-.036-3.015-1.005-.058.036a8.203 8.203 0 0 1-3.445 1l-.525.016a8.252 8.252 0 0 1-7.786-5.514.917.917 0 1 1 1.73-.608 6.42 6.42 0 0 0 9.488 3.295.917.917 0 0 1 .78-.096l1.671.557-.556-1.67a.917.917 0 0 1 .014-.62l.07-.144.163-.27c.55-.96.842-2.047.842-3.18 0-3.544-2.872-6.416-6.4-6.417l-.243.004-.138.008a.917.917 0 1 1-.097-1.831l.17-.009.292-.005Z" />
      </g>
    </svg>
  );
};

export const ChevronLeft: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M14.238 8.417a.83.83 0 1 0-1.18-1.17L9.244 11.06a.842.842 0 0 0 0 1.186l3.814 3.814a.832.832 0 1 0 1.18-1.171l-3.228-3.236 3.228-3.236Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const ChevronRight: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M9.245 8.417a.83.83 0 1 1 1.18-1.17l3.813 3.813a.842.842 0 0 1 0 1.186l-3.813 3.814a.832.832 0 1 1-1.18-1.171l3.228-3.236-3.228-3.236Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Cross: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Dislike: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M4 13h1c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1H4c-1.1 0-2 .9-2 2v7c0 1.1.9 2 2 2Zm15.89 2.8c.58 0 1.14-.26 1.54-.72.45-.52.64-1.22.54-1.93l-1.3-8.79C20.47 2.99 19.4 2 18.13 2h-.01l-8.13.04c-1.1 0-1.99.9-2 2v7.93c0 .81.25 1.61.72 2.28l4.8 6.91c.66.87 1.76 1.08 2.63.53.89-.55 1.25-1.74.85-2.76l-.79-2.87c0-.04.02-.14.08-.21.04-.05.11-.05.11-.05h3.5Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Expand: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81171 18.3529H10.1765C10.6313 18.3529 11 18.7216 11 19.1765C11 19.6313 10.6313 20 10.1765 20H4.82353C4.36871 20 4 19.6313 4 19.1765V13.8235C4 13.3687 4.36871 13 4.82353 13C5.27835 13 5.64706 13.3687 5.64706 13.8235V17.1883L9.18238 13.653C9.50399 13.3314 10.0254 13.3314 10.347 13.653C10.6686 13.9746 10.6686 14.496 10.347 14.8176L6.81171 18.3529ZM17.1883 5.64706H13.8235C13.3687 5.64706 13 5.27835 13 4.82353C13 4.36871 13.3687 4 13.8235 4H19.1765C19.6313 4 20 4.36871 20 4.82353V10.1765C20 10.6313 19.6313 11 19.1765 11C18.7216 11 18.3529 10.6313 18.3529 10.1765V6.81171L14.8176 10.347C14.496 10.6686 13.9746 10.6686 13.653 10.347C13.3314 10.0254 13.3314 9.50399 13.653 9.18238L17.1883 5.64706Z"
        fill={color}
      />
    </svg>
  );
};

export const Flip: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M11.999 6C6.47902 6 1.99902 8.24 1.99902 11C1.99902 13.24 4.93902 15.13 8.99902 15.77V19L12.999 15L8.99902 11V13.73C5.84902 13.17 3.99902 11.83 3.99902 11C3.99902 9.94 7.03902 8 11.999 8C16.959 8 19.999 9.94 19.999 11C19.999 11.73 18.539 12.89 15.999 13.53V15.58C19.529 14.81 21.999 13.05 21.999 11C21.999 8.24 17.519 6 11.999 6Z"
        fill={color}
      />
    </svg>
  );
};

export const Heart: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M16 4c2.761 0 5 2 5 5.5S12 21 12 21 3 13 3 9.5 5.239 4 8 4c1.636 0 3.088.664 4 2 .912-1.336 2.364-2 4-2Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const HeartInactive: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M15.556 4C18.706 4 21 6.408 21 9.972c0 2.314-2.33 5.511-6.987 9.878l-.675.626a1.97 1.97 0 0 1-2.676 0l-.68-.63-.665-.631C5.107 15.17 3 12.169 3 9.972 3 6.408 5.294 4 8.444 4c1.279 0 2.442.39 3.363 1.13l.193.165.193-.164C13.113 4.389 14.277 4 15.556 4Zm0 2c-1.178 0-2.136.477-2.732 1.367a.985.985 0 0 1-1.648 0C10.58 6.477 9.622 6 8.444 6 6.411 6 5 7.49 5 9.956c0 1.537 2.12 4.446 6.364 8.45L12 19l.333-.31.635-.599.618-.598C17.197 13.958 19 11.371 19 9.956 19 7.491 17.588 6 15.556 6Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const HeartEyes: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12ZM9.603 6.85C8.811 5.476 7.53 5.17 6.507 5.761c-.606.35-.993.92-1.029 1.639-.64-.329-1.328-.279-1.934.071-1.022.59-1.399 1.853-.606 3.226.792 1.372 5.936 2.585 5.936 2.585s1.522-5.062.73-6.434Zm10.752.622c1.023.59 1.4 1.853.607 3.226-.793 1.372-5.936 2.585-5.936 2.585s-1.522-5.061-.73-6.434c.792-1.372 2.074-1.678 3.097-1.087.605.35.993.92 1.028 1.64.64-.33 1.329-.28 1.934.07ZM12 20.5c4.714 0 6-4.5 6-5.5 0 0-2.686 1-6 1s-6-1-6-1c0 1 1.286 5.5 6 5.5Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const More: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M14 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-2 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Like: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M19.89 8.193c.58 0 1.14.26 1.54.72v-.01c.45.52.64 1.22.54 1.93l-1.3 8.79c-.2 1.37-1.27 2.36-2.54 2.36h-.01l-8.13-.04c-1.1-.01-1.99-.9-1.99-2v-7.94c0-.81.25-1.61.72-2.28l4.79-6.89c.66-.87 1.76-1.08 2.63-.53.89.55 1.25 1.74.85 2.76l-.79 2.87c0 .04.02.14.08.21.04.05.11.05.11.05h3.5ZM4 10.983h1c.55 0 1 .45 1 1v9c0 .55-.45 1-1 1H4c-1.1 0-2-.9-2-2v-7c0-1.1.9-2 2-2Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Pause: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M6 19h4V5H6v14Zm8-14v14h4V5h-4Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Play: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M7.524 5.15A1 1 0 0 0 6 6.002v12.42a1 1 0 0 0 1.524.852l10.092-6.21a1 1 0 0 0 0-1.704L7.524 5.15Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Search: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="m21.71 20.3-4.54-4.54A8.476 8.476 0 0 0 19 10.5C19 5.81 15.19 2 10.5 2 5.81 2 2 5.82 2 10.51c0 4.69 3.81 8.5 8.5 8.5 1.99 0 3.81-.69 5.26-1.83l4.54 4.54c.2.2.45.29.71.29.26 0 .51-.1.71-.29a.996.996 0 0 0 0-1.41l-.01-.01ZM10.5 17.01c-3.58 0-6.5-2.92-6.5-6.5s2.92-6.5 6.5-6.5 6.5 2.92 6.5 6.5-2.92 6.5-6.5 6.5Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export const Send: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M3.008 20.691a1 1 0 0 0 1.466.884L20.32 13.23a1 1 0 0 0 0-1.77L4.474 3.118A1 1 0 0 0 3.008 4L3 10.346l11 2-11 2 .008 6.345Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Touch: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <g fill={color} fillRule="evenodd">
        <path d="M7 9a5 5 0 1 1 10 0 1 1 0 1 0 2 0A7 7 0 1 0 5 9a1 1 0 0 0 2 0Z" />
        <path d="M11 9a1 1 0 1 1 2 0v3.5a1 1 0 0 0 1 1c1.996 0 3.538.766 4.335 1.332L16.28 21h-3.544c-.225-.515-.56-.977-.932-1.384-.57-.62-1.287-1.175-2.027-1.663-1.214-.8-2.598-1.492-3.681-2.027a.629.629 0 0 1 .143-.213c.122-.117.348-.228.74-.268.81-.085 1.884.177 2.65.483A1 1 0 0 0 11 15V9ZM9 9a3 3 0 1 1 6 0v2.551c2.587.266 4.47 1.505 5.207 2.242a1 1 0 0 1 .242 1.023l-2.5 7.5A1 1 0 0 1 17 23h-5a1 1 0 0 1-.986-.836c-.057-.338-.265-.738-.685-1.196-.416-.453-.986-.905-1.655-1.346-1.144-.755-2.457-1.404-3.563-1.95-.193-.096-.38-.188-.558-.278A1 1 0 0 1 4 16.5c0-.91.278-1.68.856-2.232.555-.531 1.266-.745 1.916-.813.735-.076 1.522.021 2.228.186V9Z" />
      </g>
    </svg>
  );
};

export const Trophy: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        d="M5 0a1 1 0 0 0-1 1v1H1a1 1 0 0 0-1 1v4a5 5 0 0 0 5.07 5A8.016 8.016 0 0 0 10 15.747V20H5.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5H14v-4.252A8.016 8.016 0 0 0 18.93 12H19a5 5 0 0 0 5-5V3a1 1 0 0 0-1-1h-3V1a1 1 0 0 0-1-1H5ZM2 4h2v4c0 .654.079 1.29.227 1.9A3.001 3.001 0 0 1 2 7V4Zm17.773 5.9A8.023 8.023 0 0 0 20 8V4h2v3c0 1.39-.945 2.558-2.227 2.9ZM17 5.911C17 4.059 15.756 3 14.222 3c-.908 0-1.715.352-2.222 1.059C11.493 3.352 10.686 3 9.778 3 8.244 3 7 4.059 7 5.912S12 12 12 12s5-4.235 5-6.088Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Videos: FC<IconProps> = ({
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}) => {
  return (
    <svg width={size} height={size} viewBox={DEFAULT_VIEW_BOX}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12V20C22 21.1 21.1 22 20 22H4C2.9 22 2 21.1 2 20V12C2 10.9 2.9 10 4 10H20C21.1 10 22 10.9 22 12ZM16 16L10 12.73V19.26L16 16ZM20 7C20 7.55228 19.5523 8 19 8H5C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7ZM18 3C18 2.44772 17.5523 2 17 2H7C6.44772 2 6 2.44772 6 3C6 3.55228 6.44772 4 7 4H17C17.5523 4 18 3.55228 18 3Z"
        fill={color}
      />
    </svg>
  );
};

export const Icon = {
  ArrowLeft,
  ArrowRight,
  Backspace,
  Bolt,
  Bookmark,
  Checkmark,
  Clock,
  Comments,
  Cross,
  Dislike,
  Expand,
  Flip,
  Heart,
  HeartInactive,
  HeartEyes,
  More,
  Like,
  Pause,
  Play,
  Search,
  Send,
  Touch,
  Trophy,
  Videos,
} as const;

export type IconName = keyof typeof Icon;

export const isIconName = (input: unknown): input is IconName => {
  return (
    typeof input === 'string' &&
    Object.prototype.hasOwnProperty.call(Icon, input)
  );
};

export const getIcon = (icon: IconName): FC<IconProps> => {
  return Icon[icon];
};
