import { FC, useEffect, useState } from 'react';
import { useElementVisibility } from '../utils/hooks';
import { EventPayload, trackEvent } from '../utils/tracking';

export type AdTrackingProps = Pick<EventPayload, 'group' | 'label'>;

export const AdTracking: FC<AdTrackingProps> = ({ group, label, children }) => {
  const [, setIsTracked] = useState<boolean>(false);
  const [ref, isVisible] = useElementVisibility<HTMLDivElement>();

  useEffect(() => {
    if (isVisible) {
      setIsTracked((currentState) => {
        if (!currentState) {
          trackEvent('Ad Impression', {
            group,
            label,
          });
        }
        return true;
      });
    }
  }, [group, isVisible, label]);

  return (
    <div
      ref={ref}
      onClick={() => {
        trackEvent('Ad Click', {
          group,
          label,
        });
      }}>
      {children}
    </div>
  );
};
