// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Handler<T = any> = (data?: T) => void;

export type WrappedHandler = (e: Event) => void;

const EVENT_PREFIX = 'eve:sdk:web';

const handlers: Record<string, Map<Handler, WrappedHandler>> = {};

export const emit = (topic: string, data?: Record<string, unknown>): void => {
  document.dispatchEvent(
    new CustomEvent(`${EVENT_PREFIX}:${topic}`, { detail: data }),
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const on = <T = any>(topic: string, fn: Handler<T>): void => {
  const handler: WrappedHandler = (e: Event) => {
    const event = e as CustomEvent;
    fn(event.detail);
  };
  handlers[topic] = handlers[topic] || new Map<Handler, () => void>();
  handlers[topic].set(fn, handler);
  document.addEventListener(`${EVENT_PREFIX}:${topic}`, handler, false);
};

export const off = (topic: string, fn: Handler): void => {
  const handler = handlers[topic].get(fn);
  if (handler) {
    document.removeEventListener(`${EVENT_PREFIX}:${topic}`, handler, false);
  }
};
