import { FC, Fragment } from 'react';

export type RichTextProps = {
  text: string;
};

export const RichText: FC<RichTextProps> = ({ text }) => {
  return (
    <>
      {text
        .trim()
        .split('\n')
        .map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
    </>
  );
};
