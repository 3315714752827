// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Video_video__2LGre{width:100%;height:auto;object-fit:cover}", "",{"version":3,"sources":["webpack://src/components/Video.module.scss"],"names":[],"mappings":"AAAA,oBACE,UAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":[".video {\n  width: 100%;\n  height: auto;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video": "Video_video__2LGre"
};
export default ___CSS_LOADER_EXPORT___;
