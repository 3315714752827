import { Environment, Step } from '@data/eve-sdk-web';
import { Environment as ThemeEnvironment } from './themes';

export const getDesignSystemEnvironment = (
  env: Environment,
): ThemeEnvironment => {
  switch (env) {
    case 'int':
    case 'preprod':
      return ThemeEnvironment.INT;
    default:
      return ThemeEnvironment.PROD;
  }
};

export const formatPercentage = (percentage: number): string =>
  `${percentage.toFixed(0)}%`;

export const interpolate = (
  content: string,
  values: Record<string, string | number>,
): string => {
  return content.replace(/\{\{\s*([\w]+)\s*\}\}/g, (_, key) =>
    key in values ? String(values[key]) : key,
  );
};

export const colorSchemes = ['dark', 'light'] as const;

export type ColorScheme = (typeof colorSchemes)[number];

export const getPreferredColorScheme = (): ColorScheme =>
  window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

export const getThemeNameWithColorScheme = (
  theme: string,
  fallback = 'default-dark',
): string =>
  theme
    ? new RegExp(`-(${colorSchemes.join('|')})$`).test(theme)
      ? theme
      : `${theme}-dark` // `${theme}-${getPreferredColorScheme()}
    : fallback;

const themes: Record<
  string,
  {
    theme: string;
    colors: string[];
  }
> = {
  'tms-s7': {
    theme: 'tms-interactions',
    colors: ['#8c73d1', '#c973dd', '#5aa4bb', '#6180da'],
  },
  'tvog-2022': {
    theme: 'thevoice-interactions',
    colors: ['#ff8080', '#c8b3fc', '#a5bbf4', '#f49fd2'],
  },
  wokwm: {
    theme: 'wokwm-interactions',
    colors: ['#f6a853', '#ffd55b', '#b7c8e5'],
  },
  diereimanns: {
    theme: 'reimanns-interactions',
    colors: ['#a3c4ec', '#ff8b70', '#f6c172', '#81c1b4'],
  },
  'pbb-2022': {
    theme: 'pbb-interactions',
    colors: ['#f1bf4f', '#e35692', '#0e99be', '#4fb592', '#a91a71'],
  },
  welpentrainer: {
    theme: 'welpentrainer-interactions',
    colors: ['#b27c53', '#3788be', '#025a8d', '#b1bcc2'],
  },
  'wsmds-s4': {
    theme: 'dugegenjoko-interactions',
    colors: ['#d6867f', '#79bcbe', '#cf7ebc', '#f99c7a', '#ee7ca0', '#71aed1'],
  },
  loveisking: {
    theme: 'loveisking-interactions',
    colors: ['#b681b0', '#e0a780', '#e28b8f', '#a6637e', '#c9c87c'],
  },
  tvtotal: {
    theme: 'tvtotal-interactions',
    colors: ['#ffd352', '#fbb156', '#d2a67d'],
  },
  'tbbt-s12': {
    theme: 'tbbt-interactions',
    colors: ['#f8a05f', '#f0c573', '#779add', '#709d9a', '#c96657'],
  },
  himym: {
    theme: 'himym-interactions',
    colors: ['#b06a62', '#4c4d52', '#d7aaa5', '#9b908e', '#9dbcba'],
  },
  taff: {
    theme: 'taff-interactions',
    colors: ['#f5d442', '#f59942', '#f5b942'],
  },
  newstime: {
    theme: 'newstime-interactions',
    colors: ['#b0b6c1', '#ee668e', '#75b7de', '#95d6df', '#436b9a'],
  },
  galileo: {
    theme: 'galileo-interactions',
    colors: ['#70a9e7', '#a4abe3', '#69c4eb', '#b4c1ce'],
  },
  'zo-live': {
    theme: 'zol-interactions',
    colors: ['#e56c8e', '#ff8e68', '#cbe26b', '#6ce3ac', '#82a3ff'],
  },
  s1ff: {
    theme: 'fruehstuecksfernsehen-interactions',
    colors: ['#a45298', '#63c3b6', '#f9db24', '#ffa749'],
  },
  'gntm-s17': {
    theme: 'gntm-interactions',
    colors: ['#ec99d4', '#9cd8d6', '#f7757d'],
  },
  puls24: {
    theme: 'puls24-interactions',
    colors: ['#1a2b80', '#e50046', '#9dadc4'],
  },
};

export type ThemeConfigForContext = {
  theme: string;
  colors: string[];
};

export const getThemeConfigForContext = (
  context: string,
  fallback = 'default-dark',
): ThemeConfigForContext => {
  if (themes[context]) {
    const { theme, colors } = themes[context];
    return {
      theme: getThemeNameWithColorScheme(theme, fallback),
      colors,
    };
  }
  return {
    theme: getThemeNameWithColorScheme(fallback, fallback),
    colors: [],
  };
};

export const supportsUnauthenticatedInteraction = (step: Step): boolean => {
  if (['Box'].includes(step.type)) {
    return true;
  }
  return false;
};
