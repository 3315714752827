import { FC, Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { VideoBoxDimension, vote, VideoBoxesStep } from '@data/eve-sdk-web';
import { Headline } from '../../components/Headline';
import { Copy } from '../../components/Copy';
import { EveIcon } from '../../components/EveIcon';
import { Stack } from '../../components/Stack';
import { Modal } from '../../components/Modal';
import { CommonStepProps } from './types';
import { Button } from '../../components/Button';
import { isIconName } from '../../components/Icon';
import { useSteps } from '../../utils/steps';
import { useConfig } from '../../utils/config';
import { fireConfetti } from '../../utils/confetti';
import { Video } from '../../components/Video';
import { Transition } from '../../components/Transition';
import { useToggles } from '../../utils/toggles';

import styles from './VideoBoxes.module.scss';

export type VideoBoxesProps = CommonStepProps & {
  step: VideoBoxesStep;
};

export const VideoBoxes: FC<VideoBoxesProps> = ({ group, step, onEnded }) => {
  const { context } = useConfig();
  const { isEnabled } = useToggles();
  const { goToNextStep } = useSteps();
  const [selectedDimension, setSelectedDimension] = useState<
    VideoBoxDimension | undefined
  >(step.dimensions.find((dimension) => dimension.userVote));
  const questionOverlayTransition = (step.transitions ?? []).find(
    (transition) => transition.type === 'questionOverlay',
  );
  const votingOverlayTransition = (step.transitions ?? []).find(
    (transition) => transition.type === 'votingOverlay',
  );
  const resultsOverlayTransition = (step.transitions ?? []).find(
    (transition) => transition.type === 'resultsOverlay',
  );

  useEffect(() => {
    setSelectedDimension(
      step.dimensions.find((dimension) => dimension.userVote),
    );
  }, [step]);

  return (
    <Modal
      id={`step-${group.id}-${step.id}`}
      open
      floating
      seamless
      variant="medium"
      onClose={() => onEnded()}>
      <div className={styles['video-boxes']}>
        {step.backgroundVideo ? (
          <div className={styles['video-boxes__video']}>
            <Video
              video={step.backgroundVideo}
              onEnded={() => goToNextStep()}
              seamless
              autoPlay
            />
          </div>
        ) : null}
        <Transition
          start={votingOverlayTransition?.start}
          end={resultsOverlayTransition?.end}>
          <div className={styles['video-boxes__overlay']}>
            <Stack>
              <Headline level={4} align="center">
                {step.title}
              </Headline>
              {step.copy ? <Copy align="center">{step.copy}</Copy> : null}
              <Transition
                start={questionOverlayTransition?.start}
                end={resultsOverlayTransition?.end}>
                <Stack>
                  <div className={styles['video-boxes__tiles']}>
                    {step.dimensions.map((dimension, index) => (
                      <Fragment key={dimension.id}>
                        {index > 0 ? (
                          <div className={styles['video-boxes__divider']}>
                            vs
                          </div>
                        ) : null}
                        <div
                          className={classNames(styles['video-boxes__item'], {
                            [styles['video-boxes__item--active']]:
                              selectedDimension?.id === dimension.id,
                          })}
                          onClick={() => {
                            if (selectedDimension?.id === dimension.id) {
                              setSelectedDimension(undefined);
                            } else {
                              setSelectedDimension(dimension);
                            }
                          }}>
                          <div
                            className={classNames(
                              styles['video-boxes__content'],
                              {
                                [styles['video-boxes__content--icon']]:
                                  dimension.icon && !dimension.label,
                              },
                            )}>
                            {dimension.icon ? (
                              <div className={styles['video-boxes__icon']}>
                                <EveIcon name={dimension.icon} />
                              </div>
                            ) : null}
                            {dimension.label ? (
                              <div className={styles['video-boxes__label']}>
                                {dimension.label}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  </div>
                  <div className={styles['video-boxes__action']}>
                    <Button
                      onClick={async () => {
                        if (selectedDimension) {
                          await vote(
                            context,
                            step.interactionId,
                            selectedDimension.id,
                            1,
                          );
                          if (isEnabled('confetti')) {
                            fireConfetti();
                          }
                          goToNextStep();
                        }
                      }}
                      icon={
                        step.cta && isIconName(step.cta.icon)
                          ? step.cta.icon
                          : undefined
                      }
                      label={step.cta?.label ?? ''}
                      disabled={!selectedDimension}
                    />
                  </div>
                </Stack>
              </Transition>
            </Stack>
          </div>
        </Transition>
      </div>
    </Modal>
  );
};
