import { FC } from 'react';
import classNames from 'classnames';

import styles from './Copy.module.scss';

export type CopyProps = {
  size?: 'tiny' | 'small' | 'medium' | 'large';
  align?: 'left' | 'center' | 'right';
};

export const Copy: FC<CopyProps> = ({
  size = 'medium',
  align = 'left',
  children,
}) => {
  return (
    <div
      className={classNames(
        styles.copy,
        styles[`copy--${size}`],
        styles[`copy--${align}`],
      )}>
      {children}
    </div>
  );
};
