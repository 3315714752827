export const slideUpAnimation = (delay: number) => {
  return {
    initial: { y: 15, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.2,
        delay: delay,
      },
    },
  };
};

export const growCircleAnimation = (delay: number) => {
  return {
    initial: { opacity: 0, scale: 0.5 },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: delay,
        delay: 0.3,
        ease: [0, 0.71, 0.2, 1.01],
      },
    },
  };
};

export const popInAnimation = (delay: number) => {
  return {
    initial: { opacity: 0, scale: 0.5 },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.3,
        delay: delay,
        ease: [0, 0.71, 0.2, 1.01],
        type: 'spring',
        bounce: 0.5,
      },
    },
  };
};

export const slideUpAndFadeAnimation = (
  initialY: number,
  y: number[],
  delay: number,
) => {
  return {
    initial: { y: initialY, opacity: 0 },
    animate: {
      y: y,
      opacity: [0, 1, 0],
      transition: {
        delay: delay,
        y: {
          duration: 3,
          ease: 'easeOut',
        },
        opacity: {
          duration: 3,
          ease: 'easeOut',
        },
      },
    },
  };
};
