import { log } from './logging';

export type EventPayload = {
  /** Event action */
  group: string;
  /** Event label */
  label: string;
  /** Event value */
  value?: number;
  /** Non-intercation? */
  isNonInteraction?: 0 | 1;
};

export const trackEvent = (
  /** Event category */
  eventName: string,
  eventPayload: EventPayload,
): void => {
  try {
    if (globalThis.etWebApi) {
      globalThis.etWebApi.trackEvent(eventName, eventPayload);
    }
    if (process.env.NODE_ENV !== 'production') {
      log(`[TRACKING] ${eventName}`, eventPayload);
    }
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Error while tracking', e);
    }
  }
};

export const onVisibilityChange = (
  element: HTMLElement,
  callback?: (isVisible: boolean) => void,
): void => {
  let oldVisible = false;
  const observer = new IntersectionObserver((changes) => {
    const visible = changes.some((change) => change.isIntersecting);
    if (visible !== oldVisible) {
      oldVisible = visible;
      callback?.(visible);
    }
  });
  observer.observe(element);
};
