import { FC } from 'react';
import { ResultsStep } from '@data/eve-sdk-web';
import { CommonResultsViewProps, CommonStepProps } from './types';
import { Button } from '../../components/Button';
import { isIconName } from '../../components/Icon';
import { useSteps } from '../../utils/steps';
import { Transition } from '../../components/Transition';
import { ResultsPodium } from './ResultsPodium';
import { ResultsList } from './ResultsList';

export type ResultsProps = CommonStepProps & {
  step: ResultsStep;
};

export const Results: FC<ResultsProps> = ({
  group,
  interaction,
  step,
  onEnded,
}) => {
  const { goToNextStep } = useSteps();
  const actionTransition = (step.transitions ?? []).find(
    (transition) => transition.type === 'actionOverlay',
  );
  const footer = step.cta ? (
    <Transition start={actionTransition?.start} end={actionTransition?.end}>
      <Button
        onClick={() => goToNextStep()}
        icon={isIconName(step.cta.icon) ? step.cta.icon : undefined}
        label={step.cta.label}
      />
    </Transition>
  ) : undefined;
  const commonViewProps: CommonResultsViewProps = {
    group,
    interaction,
    step,
    footer,
    onEnded,
  };

  return step.view === 'podium' ? (
    <ResultsPodium {...commonViewProps} />
  ) : step.view === 'list' ? (
    <ResultsList {...commonViewProps} />
  ) : null;
};
