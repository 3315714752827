import { FC, useEffect, useState } from 'react';
import { Comment } from '@data/eve-sdk-web';
import { Send } from '../components/Icon';
import { Input } from '../components/Input';

import styles from './CommentEditor.module.scss';

export type CommentEditorProps = {
  placeholder: string;
  comment?: Comment;
  maxLength?: number;
  onSubmit: (newMessage: string) => void;
};

export const CommentEditor: FC<CommentEditorProps> = ({
  placeholder,
  comment,
  maxLength,
  onSubmit,
}) => {
  const [message, setMessage] = useState<string>(comment?.message ?? '');

  useEffect(() => {
    setMessage(comment?.message ?? '');
  }, [comment]);

  return (
    <div className={styles['comment-editor']}>
      <div className={styles['comment-editor__input']}>
        <Input
          variant="highlighted"
          multi
          placeholder={placeholder}
          maxLength={maxLength}
          value={message}
          suffix={
            <button
              className={styles['comment-editor__send']}
              onClick={() => {
                onSubmit(message);
                setMessage('');
              }}>
              <Send size={20} />
            </button>
          }
          onChange={(newMessage) => setMessage(newMessage)}
        />
      </div>
    </div>
  );
};
