import { FC, Children } from 'react';
import classNames from 'classnames';

import styles from './Stack.module.scss';

export type StackGap = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

export type StackProps = {
  horizontal?: boolean;
  responsive?: boolean;
  gap?: StackGap;
  variant?: 'default' | 'narrow' | 'medium';
  align?: 'left' | 'center' | 'right';
};

export const Stack: FC<StackProps> = ({
  horizontal,
  responsive,
  gap = 'md',
  variant = 'default',
  align = 'left',
  children,
}) => {
  return (
    <div
      style={{
        '--gap': `var(--ds-space-small-${gap})`,
      }}
      className={classNames(
        styles.stack,
        styles[`stack--${horizontal ? 'horizontal' : 'vertical'}`],
        styles[`stack--${responsive ? 'responsive' : 'static'}`],
        styles[`stack--${variant}`],
        styles[`stack--${align}`],
      )}>
      {Children.map(children, (child) =>
        child ? <div className={styles['stack__item']}>{child}</div> : null,
      )}
    </div>
  );
};
