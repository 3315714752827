import { FC, useEffect, useMemo, useState } from 'react';
import { FlowGroup, Step, focus, blur, Flow } from '@data/eve-sdk-web';
import { StepsApi, StepsContext } from '../utils/steps';
import { StepSwitch } from './StepSwitch';
import { useConfig } from '../utils/config';
import { log } from '../utils/logging';

export type GroupProps = {
  group: FlowGroup;
  onClose: () => void;
};

export const Group: FC<GroupProps> = ({ group, onClose }) => {
  const { context } = useConfig();
  const consolidatedInteractions = useMemo(
    () => group.interactions.map((interaction) => interaction).flat(),
    [group.interactions],
  );
  const consolidatedSteps = useMemo(
    () =>
      group.interactions
        .map((interaction) => interaction.steps)
        .flat() as unknown as Step[],
    [group.interactions],
  );
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const maxIndex = consolidatedSteps.length - 1;

  const api: StepsApi = {
    steps: consolidatedSteps,
    step: consolidatedSteps[currentStepIndex],
    hasPreviousStep: currentStepIndex > 0,
    hasNextStep: currentStepIndex < maxIndex,
    goToPreviousStep: () => {
      const newIndex = Math.max(0, currentStepIndex - 1);
      setCurrentStepIndex(newIndex);
    },
    goToNextStep: () => {
      const newIndex = currentStepIndex + 1;
      if (newIndex > maxIndex) {
        onClose();
        log('[EVE] Blur');
        blur();
      } else {
        setCurrentStepIndex(newIndex);
      }
    },
  };
  const currentStep: Step | undefined = useMemo(
    () => consolidatedSteps[currentStepIndex],
    [consolidatedSteps, currentStepIndex],
  );
  const currentInteraction: Flow | undefined = useMemo(
    () => consolidatedInteractions[currentStepIndex],
    [consolidatedInteractions, currentStepIndex],
  );

  useEffect(() => {
    if (currentInteraction?.id && currentStep?.id) {
      log('[EVE] Focus', {
        context,
        group: group.id,
        interaction: currentInteraction.id,
        step: currentStep.id,
      });
      focus(context, group.id, currentInteraction.id, currentStep.id);
    }
  }, [context, group.id, currentInteraction?.id, currentStep?.id]);

  return (
    <StepsContext.Provider value={api}>
      {currentStep ? (
        <StepSwitch
          key={`step-${group.id}-${currentStep.id}`}
          group={group}
          interaction={currentInteraction}
          step={currentStep}
          onEnded={() => {
            setCurrentStepIndex(-1);
            onClose();
            log('[EVE] Blur');
            blur();
          }}
        />
      ) : null}
    </StepsContext.Provider>
  );
};
