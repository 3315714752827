export const parseColors = (colors: string | string[]): string[] => {
  const base = (Array.isArray(colors) ? colors : [colors])
    .map((color) => color.split(/[|,]/))
    .flat();
  return base
    .map((color) => color.trim())
    .filter(Boolean)
    .map((color) =>
      color.startsWith('#') || color.startsWith('rgb') ? color : `#${color}`,
    );
};

export const getColorForIndex = (colors: string[], index: number): string =>
  colors[index % colors.length];
