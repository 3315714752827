// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthWall_auth-wall__illustration__2mprP{display:block;width:auto;height:var(--ds-size-small-3xl);margin:0 auto;color:var(--ds-colors-surface-on-base-50)}", "",{"version":3,"sources":["webpack://src/fragments/AuthWall.module.scss"],"names":[],"mappings":"AAAA,yCACE,aAAA,CACA,UAAA,CACA,+BAAA,CACA,aAAA,CACA,yCAAA","sourcesContent":[".auth-wall__illustration {\n  display: block;\n  width: auto;\n  height: var(--ds-size-small-3xl);\n  margin: 0 auto;\n  color: var(--ds-colors-surface-on-base-50);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auth-wall__illustration": "AuthWall_auth-wall__illustration__2mprP"
};
export default ___CSS_LOADER_EXPORT___;
