import { useEffect, useState } from 'react';
import createOasisPlayer from '@p7s1/oasis-player';
import {
  ContentResource,
  PlayerApi,
  PlayerConfig,
  PlayerEvent,
} from '@p7s1/oasis-types';

export const useOasisPlayer = (
  config?: null | {
    id: PlayerConfig['id'];
    element: PlayerConfig['element'];
    videoElement?: PlayerConfig['videoElement'];
    seamless?: boolean;
  },
  content?: ContentResource | null,
): PlayerApi | null => {
  const [player, setPlayer] = useState<PlayerApi | null>(null);

  useEffect(() => {
    if (config) {
      if (!player) {
        createOasisPlayer(config).then((newPlayer) => {
          if (config.seamless) {
            newPlayer.removeUi();
          }
          setPlayer(newPlayer);
        });
      }
    }
  }, [config, player]);

  useEffect(() => {
    if (player && content) {
      player.setContent(content);
    }
  }, [player, content]);

  useEffect(() => {
    if (player) {
      const handler = () => {
        player.play();
      };
      player.on(PlayerEvent.CONTENT_LOADED, handler);
      return () => {
        player.off(PlayerEvent.CONTENT_LOADED, handler);
      };
    }
  }, [player]);

  useEffect(() => {
    if (player) {
      return () => {
        setPlayer(null);
        player.destroy();
      };
    }
  }, [player]);

  return player;
};
