import { FC, Fragment } from 'react';
import classNames from 'classnames';

import styles from './Tiles.module.scss';
import { EveIcon } from './EveIcon';
import { getImageUrl } from '../utils/images';
import { EveImage } from '@data/eve-sdk-web';

export type TilesItem = {
  id: string;
  icon?: string;
  image?: EveImage | null;
  label: string;
};

export type TilesProps = {
  items: TilesItem[];
  onSelect: (newSelected: string) => void;
  selected?: string;
  divider?: string;
};

export const Tiles: FC<TilesProps> = ({
  items,
  selected,
  divider,
  onSelect,
}) => {
  return (
    <div
      className={classNames(styles['tiles'], {
        [styles['tiles--divided']]: divider,
      })}>
      {items.map((item, index) => (
        <Fragment key={item.id}>
          {index > 0 && divider ? (
            <div className={styles['tiles__divider']}>{divider}</div>
          ) : null}
          <div
            className={classNames(styles['tiles__item'], {
              [styles['tiles__item--active']]: selected === item.id,
            })}
            onClick={() => onSelect(item.id)}>
            <div
              className={classNames(styles['tiles__content'], {
                [styles['tiles__content--icon']]: item.icon && !item.label,
              })}>
              {item.icon ? (
                <div className={styles['tiles__icon']}>
                  <EveIcon
                    name={item.icon}
                    size={item.label ? undefined : 32}
                  />
                </div>
              ) : null}
              {item.label ? (
                <div className={styles['tiles__label']}>{item.label}</div>
              ) : null}
            </div>
            {item.image ? (
              <img
                src={getImageUrl(item.image.url, 'square')}
                alt={item.label ?? ''}
                className={styles['tiles__visual']}
              />
            ) : null}
          </div>
        </Fragment>
      ))}
    </div>
  );
};
