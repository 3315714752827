"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCommentsStep = exports.isRaffleStep = exports.isGuessStep = exports.isPlayerScoreStep = exports.isVideoBattleStep = exports.isVideoBoxesStep = exports.isBattleStep = exports.isBoxStep = exports.isThankYouStep = exports.isResultsStep = exports.isVideoInfoStep = exports.isInfoStep = void 0;
var isInfoStep = function (step) {
    return step.type === 'Info';
};
exports.isInfoStep = isInfoStep;
var isVideoInfoStep = function (step) {
    return step.type === 'VideoInfo';
};
exports.isVideoInfoStep = isVideoInfoStep;
var isResultsStep = function (step) {
    return step.type === 'Results';
};
exports.isResultsStep = isResultsStep;
var isThankYouStep = function (step) {
    return step.type === 'ThankYou';
};
exports.isThankYouStep = isThankYouStep;
var isBoxStep = function (step) { return step.type === 'Box'; };
exports.isBoxStep = isBoxStep;
var isBattleStep = function (step) {
    return step.type === 'Battle';
};
exports.isBattleStep = isBattleStep;
var isVideoBoxesStep = function (step) {
    return step.type === 'VideoBoxes';
};
exports.isVideoBoxesStep = isVideoBoxesStep;
var isVideoBattleStep = function (step) {
    return step.type === 'VideoBattle';
};
exports.isVideoBattleStep = isVideoBattleStep;
var isPlayerScoreStep = function (step) {
    return step.type === 'PlayersScore';
};
exports.isPlayerScoreStep = isPlayerScoreStep;
var isGuessStep = function (step) {
    return step.type === 'Guess';
};
exports.isGuessStep = isGuessStep;
var isRaffleStep = function (step) {
    return step.type === 'Raffle';
};
exports.isRaffleStep = isRaffleStep;
var isCommentsStep = function (step) {
    return step.type === 'Comments';
};
exports.isCommentsStep = isCommentsStep;
