import { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';

import styles from './Input.module.scss';

export type InputVariant = 'default' | 'highlighted';

export type InputProps = {
  variant?: InputVariant;
  value?: string;
  placeholder?: string;
  maxLength?: number;
  multi?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onChange?: (newValue: string) => void;
};

export const Input: FC<InputProps> = ({
  variant = 'default',
  value,
  placeholder,
  maxLength,
  multi,
  prefix,
  suffix,
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div
      className={classNames(styles['input'], styles[`input--${variant}`], {
        [styles['input--focused']]: isFocused,
        [styles['input--filled']]: value?.length,
      })}>
      {prefix ? <div className={styles['input__prefix']}>{prefix}</div> : null}
      <div className={styles['input__field']}>
        {multi ? (
          <>
            <div className={styles['input__clone']}>{value}</div>
            <textarea
              cols={10}
              rows={10}
              placeholder={placeholder}
              className={styles['input__element']}
              value={value}
              maxLength={maxLength}
              onChange={(e) => onChange?.(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </>
        ) : (
          <input
            type="text"
            placeholder={placeholder}
            className={styles['input__element']}
            value={value}
            maxLength={maxLength}
            onChange={(e) => onChange?.(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        )}
      </div>
      {suffix ? <div className={styles['input__suffix']}>{suffix}</div> : null}
    </div>
  );
};
