// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommentEditor_comment-editor__1YYKp{display:flex}.CommentEditor_comment-editor__input__3rP-E{flex:1 1 auto;position:relative}.CommentEditor_comment-editor__send__32OLe{flex:0 0 auto;display:flex;cursor:pointer;color:inherit;opacity:var(--ds-emphasis-medium);transition:opacity 200ms ease}.CommentEditor_comment-editor__send__32OLe:hover{opacity:var(--ds-emphasis-full)}", "",{"version":3,"sources":["webpack://src/fragments/CommentEditor.module.scss"],"names":[],"mappings":"AAEA,qCACE,YAAA,CAGF,4CACE,aAAA,CACA,iBAAA,CAGF,2CACE,aAAA,CACA,YAAA,CACA,cAAA,CACA,aAAA,CACA,iCAAA,CACA,6BAAA,CAEA,iDACE,+BAAA","sourcesContent":["@import '../scss/typography';\n\n.comment-editor {\n  display: flex;\n}\n\n.comment-editor__input {\n  flex: 1 1 auto;\n  position: relative;\n}\n\n.comment-editor__send {\n  flex: 0 0 auto;\n  display: flex;\n  cursor: pointer;\n  color: inherit;\n  opacity: var(--ds-emphasis-medium);\n  transition: opacity 200ms ease;\n\n  &:hover {\n    opacity: var(--ds-emphasis-full);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment-editor": "CommentEditor_comment-editor__1YYKp",
	"comment-editor__input": "CommentEditor_comment-editor__input__3rP-E",
	"comment-editor__send": "CommentEditor_comment-editor__send__32OLe"
};
export default ___CSS_LOADER_EXPORT___;
