import { FC, useEffect, useState } from 'react';
import { guess, GuessDimension, GuessStep, search } from '@data/eve-sdk-web';
import { useDebounce } from 'usehooks-ts';
import { Headline } from '../../components/Headline';
import { Copy } from '../../components/Copy';
import { Stack } from '../../components/Stack';
import { Modal } from '../../components/Modal';
import { CommonStepProps } from './types';
import { Button } from '../../components/Button';
import { Backspace, isIconName, Search } from '../../components/Icon';
import { useSteps } from '../../utils/steps';
import { Transition } from '../../components/Transition';
import { useConfig } from '../../utils/config';
import { Input } from '../../components/Input';
import { Tiles } from '../../components/Tiles';
import { AuthWall } from '../AuthWall';
import { supportsUnauthenticatedInteraction } from '../../utils/ui';

import styles from './Guess.module.scss';

export type GuessProps = CommonStepProps & {
  step: GuessStep;
};

export const Guess: FC<GuessProps> = ({
  group,
  step,
  interaction,
  onEnded,
}) => {
  const { context, miraToken } = useConfig();
  const { goToNextStep } = useSteps();
  const [showAuthWall, setShowAuthWall] = useState<boolean>(false);
  const [selectedDimension, setSelectedDimension] = useState<
    GuessDimension | undefined
  >(undefined);
  const actionTransition = (step.transitions ?? []).find(
    (transition) => transition.type === 'actionOverlay',
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    (async () => {
      await search(context, interaction.id, debouncedSearchTerm);
    })();
  }, [context, debouncedSearchTerm, interaction.id]);

  return (
    <>
      <Modal
        id={`step-${group.id}-${step.id}`}
        open
        floating
        variant="medium"
        onClose={() => onEnded()}
        footer={
          step.cta ? (
            <Transition
              start={actionTransition?.start}
              end={actionTransition?.end}>
              <Button
                onClick={async () => {
                  if (selectedDimension && step.dimensions.length) {
                    if (miraToken || supportsUnauthenticatedInteraction(step)) {
                      await guess(
                        context,
                        step.interactionId,
                        step.dimensions[0]?.id,
                        selectedDimension.id,
                      );
                      goToNextStep();
                    } else {
                      setShowAuthWall(true);
                    }
                  }
                }}
                icon={
                  step.cta && isIconName(step.cta.icon)
                    ? step.cta.icon
                    : undefined
                }
                label={step.cta?.label ?? ''}
                disabled={!selectedDimension}
              />
            </Transition>
          ) : undefined
        }>
        <Stack>
          <Headline level={4} align="center">
            {step.title}
          </Headline>
          {step.copy ? <Copy align="center">{step.copy}</Copy> : null}
          <Input
            value={searchTerm}
            prefix={
              <div className={styles['guess__search']}>
                <Search size={20} />
              </div>
            }
            suffix={
              searchTerm ? (
                <button
                  className={styles['guess__clear']}
                  onClick={() => setSearchTerm('')}>
                  <Backspace size={20} />
                </button>
              ) : null
            }
            onChange={(newMessage) => setSearchTerm(newMessage)}
          />
          {step.guessItems.length ? (
            <Tiles
              items={step.guessItems.map((item) => ({
                id: item.id,
                image: item.image,
                label: item.label ?? '',
              }))}
              selected={selectedDimension?.id}
              onSelect={(newSelected) => {
                if (selectedDimension?.id === newSelected) {
                  setSelectedDimension(undefined);
                } else {
                  setSelectedDimension(
                    step.guessItems.find((item) => item.id === newSelected),
                  );
                }
              }}
            />
          ) : null}
        </Stack>
      </Modal>
      {showAuthWall ? (
        <AuthWall onClose={() => setShowAuthWall(false)} />
      ) : null}
    </>
  );
};
