import { FC } from 'react';
import classNames from 'classnames';
import { getIcon, IconName } from './Icon';

import styles from './Badge.module.scss';

export type BadgeVariant = 'default' | 'emphasized' | 'positive';

export type BadgeProps = {
  icon?: IconName;
  label?: string;
  variant?: BadgeVariant;
};

export const Badge: FC<BadgeProps> = ({ icon, label, variant = 'default' }) => {
  const IconComponent = icon && getIcon(icon);

  return (
    <div className={classNames(styles.badge, styles[`badge--${variant}`])}>
      {IconComponent ? (
        <div className={styles['badge__icon']}>
          <IconComponent size={16} />
        </div>
      ) : null}
      {label ? <div className={styles['badge__label']}>{label}</div> : null}
    </div>
  );
};
