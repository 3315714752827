import { ResultDimension } from '@data/eve-sdk-web';
import { ReactNode } from 'react';

export type PodiumRank = {
  rank: number;
  dimension: ResultDimension;
  fin: ReactNode;
};

export const getPodiumRanking = (dimensions: ResultDimension[]): PodiumRank[] =>
  [
    {
      rank: 2,
      dimension: dimensions[1],
      fin: (
        <svg viewBox="0 0 96 24">
          <path
            d="M96 -0.00012207H18.506L0 23.9999H96V-0.00012207Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
    {
      rank: 1,
      dimension: dimensions[0],
      fin: (
        <svg viewBox="0 0 96 20">
          <path
            d="M18.506 -0.00012207H77.494L96 19.9999H0L18.506 -0.00012207Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
    {
      rank: 3,
      dimension: dimensions[2],
      fin: (
        <svg viewBox="0 0 96 24">
          <path
            d="M0 -0.00012207H77.494L96 23.9999H0V-0.00012207Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
  ].filter((item) => item.dimension);
