import { FC } from 'react';
import { EveVideo } from '@data/eve-sdk-web';
import { OasisPlayer } from './OasisPlayer';

import styles from './Video.module.scss';

export const isOasisVideo = (video?: EveVideo): boolean =>
  Boolean(video && video.id && !video.url);

export type VideoProps = {
  video: EveVideo;
  autoPlay?: boolean;
  seamless?: boolean;
  onEnded?: () => void;
};

export const Video: FC<VideoProps> = ({
  video,
  seamless,
  autoPlay,
  onEnded,
}) => {
  if (!video) {
    return null;
  }

  if (isOasisVideo(video)) {
    return (
      <div className={styles.video}>
        <OasisPlayer
          content={{
            id: video.id,
          }}
          onPlaylistEnd={() => onEnded?.()}
          seamless={seamless}
        />
      </div>
    );
  }

  return (
    <video
      className={styles.video}
      src={video.url}
      autoPlay={autoPlay}
      onEnded={() => onEnded?.()}
    />
  );
};
