import { FC } from 'react';
import classNames from 'classnames';

import styles from './Avatar.module.scss';

export type AvatarSize = 'small' | 'medium' | 'large';

export type AvatarProps = {
  image?: string;
  name?: string;
  size?: AvatarSize;
};

const getInitials = (name: string, length: number): string =>
  name
    .split(/\s+/)
    .map((item) => item.trim().substr(0, 1).toUpperCase())
    .filter(Boolean)
    .slice(0, length)
    .join('');

export const Avatar: FC<AvatarProps> = ({ image, name, size = 'medium' }) => {
  return (
    <div
      className={classNames(styles.avatar, styles[`avatar--${size}`], {
        [styles['avatar--image']]: image,
        [styles['avatar--name']]: name,
      })}>
      {image ? (
        <img src={image} alt={name} className={styles['avatar__image']} />
      ) : name ? (
        <div className={styles.avatar__name}>
          {getInitials(name, size === 'small' ? 1 : 2)}
        </div>
      ) : null}
    </div>
  );
};
