import { FC } from 'react';
import { Modal } from '../../components/Modal';
import { CommonStepProps } from './types';
import { Button } from '../../components/Button';
import { isIconName } from '../../components/Icon';
import { useSteps } from '../../utils/steps';
import { VideoInfoStep } from '@data/eve-sdk-web';
import { Transition } from '../../components/Transition';
import { Video } from '../../components/Video';

import styles from './VideoBoxes.module.scss';

export type VideoInfoProps = CommonStepProps & {
  step: VideoInfoStep;
};

export const VideoInfo: FC<VideoInfoProps> = ({ group, step, onEnded }) => {
  const { goToNextStep } = useSteps();
  const actionTransition = (step.transitions ?? []).find(
    (transition) => transition.type === 'actionOverlay',
  );

  return (
    <Modal
      id={`step-${group.id}-${step.id}`}
      open
      floating
      seamless
      fullscreen
      variant="medium"
      onClose={() => onEnded()}
      footer={
        step.cta ? (
          <Transition
            start={actionTransition?.start}
            end={actionTransition?.end}>
            <Button
              onClick={() => goToNextStep()}
              icon={isIconName(step.cta.icon) ? step.cta.icon : undefined}
              label={step.cta.label}
            />
          </Transition>
        ) : undefined
      }>
      <div className={styles['video-boxes__video']}>
        <Video
          video={step.backgroundVideo}
          onEnded={() => goToNextStep()}
          autoPlay
        />
      </div>
    </Modal>
  );
};
