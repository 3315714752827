"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.blur = exports.focus = exports.postCommentReaction = exports.loadMoreComments = exports.changeCommentCategory = exports.deleteComment = exports.reportComment = exports.postComment = exports.search = exports.guess = exports.vote = exports.subscribe = exports.setAuth = exports.setup = void 0;
var EVE_SDK_VERSION = '0.22.0';
var EVE_SDK_VERSION_UNSTABLE = false;
var EVE_SDK_CONFIGS = {
    int: "https://eve-int.p7s1.io/sdk/".concat(EVE_SDK_VERSION_UNSTABLE ? 'unstable' : 'config', "/config.v").concat(EVE_SDK_VERSION, ".json"),
    preprod: "https://eve".concat(EVE_SDK_VERSION_UNSTABLE ? '-int' : '-preprod', ".p7s1.io/sdk/").concat(EVE_SDK_VERSION_UNSTABLE ? 'unstable' : 'config', "/config.v").concat(EVE_SDK_VERSION, ".json"),
    prod: "https://eve".concat(EVE_SDK_VERSION_UNSTABLE ? '-int' : '', ".p7s1.io/sdk/").concat(EVE_SDK_VERSION_UNSTABLE ? 'unstable' : 'config', "/config.v").concat(EVE_SDK_VERSION, ".json"),
};
var subscriptions = {};
var cache = {};
var waitForEveApi = function (check, success, timeout, iterations) {
    if (iterations === void 0) { iterations = 500; }
    if (check()) {
        success();
    }
    else {
        if (iterations > 0) {
            setTimeout(function () {
                waitForEveApi(check, success, timeout, iterations - 1);
            }, 20);
        }
        else {
            timeout === null || timeout === void 0 ? void 0 : timeout();
        }
    }
};
var isSetUpAlready = false;
var setup = function (env, miraToken) { return __awaiter(void 0, void 0, void 0, function () {
    var config;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!!isSetUpAlready) return [3 /*break*/, 3];
                return [4 /*yield*/, loadConfig(EVE_SDK_CONFIGS[env])];
            case 1:
                config = _a.sent();
                return [4 /*yield*/, loadBundle(config)];
            case 2:
                _a.sent();
                if (globalThis.eveCoreSetup) {
                    globalThis.eveCoreSetup(config, miraToken);
                }
                isSetUpAlready = true;
                _a.label = 3;
            case 3: return [2 /*return*/, new Promise(function (resolve, reject) {
                    waitForEveApi(function () { var _a; return Boolean((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.subscribe); }, resolve, reject);
                })];
        }
    });
}); };
exports.setup = setup;
var setAuth = function (miraToken) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, reject) {
                waitForEveApi(function () { var _a; return Boolean((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.setAuth); }, function () {
                    var _a;
                    if ((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.setAuth) {
                        globalThis.eveCore.setAuth(miraToken);
                        resolve();
                    }
                    else {
                        console.error('Cannot update user/token yet. Finish the setup first.');
                        reject();
                    }
                }, reject);
            })];
    });
}); };
exports.setAuth = setAuth;
var loadConfig = function (url) { return __awaiter(void 0, void 0, void 0, function () {
    var response, config, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, fetch(url)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                config = (_a.sent());
                return [2 /*return*/, config];
            case 3:
                error_1 = _a.sent();
                return [2 /*return*/, Promise.reject(error_1)];
            case 4: return [2 /*return*/];
        }
    });
}); };
var loadBundle = function (config) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!config.libraryUrl) {
            return [2 /*return*/, Promise.reject(new Error('The config is missing the library URL.'))];
        }
        return [2 /*return*/, new Promise(function (resolve, reject) {
                var id = "eveCore_".concat(config.libraryVersion);
                if (!document.getElementById(id)) {
                    var script = document.createElement('script');
                    script.src = config.libraryUrl;
                    script.id = id;
                    script.onload = function () {
                        return waitForEveApi(function () { return Boolean(globalThis.eveCoreSetup); }, resolve);
                    };
                    script.onerror = reject;
                    document.head.appendChild(script);
                }
                else {
                    resolve();
                }
            })];
    });
}); };
var subscribe = function (productId, callback) { return __awaiter(void 0, void 0, void 0, function () {
    var productIds, _loop_1, _i, productIds_1, id;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                productIds = Array.isArray(productId)
                    ? productId
                    : [productId];
                if (!((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.subscribe)) return [3 /*break*/, 5];
                _loop_1 = function (id) {
                    var isAlreadySubscribed;
                    return __generator(this, function (_f) {
                        switch (_f.label) {
                            case 0:
                                isAlreadySubscribed = !!subscriptions[id];
                                // Create stack if necessary and push callback onto stack for current ID
                                subscriptions[id] = (_b = subscriptions[id]) !== null && _b !== void 0 ? _b : [];
                                (_c = subscriptions[id]) === null || _c === void 0 ? void 0 : _c.push(callback);
                                if (!isAlreadySubscribed) return [3 /*break*/, 1];
                                // Replay existing results from prevous subscriptions
                                callback((_d = cache[id]) !== null && _d !== void 0 ? _d : []);
                                return [3 /*break*/, 3];
                            case 1: 
                            // Subscribe
                            return [4 /*yield*/, globalThis.eveCore.subscribe(id, function (groups) {
                                    var _a;
                                    cache[id] = Object.values(groups).flat();
                                    // Apply updates to all present and future subscribers (incl. the new one)
                                    (_a = subscriptions[id]) === null || _a === void 0 ? void 0 : _a.forEach(function (cb) { var _a; return cb((_a = cache[id]) !== null && _a !== void 0 ? _a : []); });
                                })];
                            case 2:
                                // Subscribe
                                _f.sent();
                                _f.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                };
                _i = 0, productIds_1 = productIds;
                _e.label = 1;
            case 1:
                if (!(_i < productIds_1.length)) return [3 /*break*/, 4];
                id = productIds_1[_i];
                return [5 /*yield**/, _loop_1(id)];
            case 2:
                _e.sent();
                _e.label = 3;
            case 3:
                _i++;
                return [3 /*break*/, 1];
            case 4: return [3 /*break*/, 6];
            case 5:
                console.error('Cannot subscribe yet. Finish the setup first.');
                _e.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}); };
exports.subscribe = subscribe;
var vote = function (context, bucketId, dimension, value) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!((_b = globalThis.eveCore) === null || _b === void 0 ? void 0 : _b.vote)) return [3 /*break*/, 2];
                return [4 /*yield*/, globalThis.eveCore.vote(context, bucketId, (_a = {},
                        _a[dimension] = value,
                        _a))];
            case 1:
                _c.sent();
                return [3 /*break*/, 3];
            case 2:
                console.error('Cannot vote yet. Finish the setup first.');
                _c.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.vote = vote;
var guess = function (context, bucketId, guess, value) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!((_b = globalThis.eveCore) === null || _b === void 0 ? void 0 : _b.guess)) return [3 /*break*/, 2];
                return [4 /*yield*/, globalThis.eveCore.guess(context, bucketId, (_a = {},
                        _a[guess] = value,
                        _a))];
            case 1:
                _c.sent();
                return [3 /*break*/, 3];
            case 2:
                console.error('Cannot guess yet. Finish the setup first.');
                _c.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.guess = guess;
var search = function (context, bucketId, search) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.search)) return [3 /*break*/, 2];
                return [4 /*yield*/, globalThis.eveCore.search(context, bucketId, search)];
            case 1:
                _b.sent();
                return [3 /*break*/, 3];
            case 2:
                console.error('Cannot search yet. Finish the setup first.');
                _b.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.search = search;
var postComment = function (productId, interactionId, message, commentId) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.postComment)) return [3 /*break*/, 2];
                return [4 /*yield*/, globalThis.eveCore.postComment(productId, interactionId, message, commentId)];
            case 1:
                _b.sent();
                return [3 /*break*/, 3];
            case 2:
                console.error('Cannot post comment yet. Finish the setup first.');
                _b.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.postComment = postComment;
var reportComment = function (productId, interactionId, commentId) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.reportComment)) return [3 /*break*/, 2];
                return [4 /*yield*/, globalThis.eveCore.reportComment(productId, interactionId, commentId)];
            case 1:
                _b.sent();
                return [3 /*break*/, 3];
            case 2:
                console.error('Cannot report comment yet. Finish the setup first.');
                _b.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.reportComment = reportComment;
var deleteComment = function (productId, interactionId, commentId) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.deleteComment)) return [3 /*break*/, 2];
                return [4 /*yield*/, globalThis.eveCore.deleteComment(productId, interactionId, commentId)];
            case 1:
                _b.sent();
                return [3 /*break*/, 3];
            case 2:
                console.error('Cannot delete comment yet. Finish the setup first.');
                _b.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.deleteComment = deleteComment;
var changeCommentCategory = function (productId, interactionId, categoryId) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.changeCommentCategory)) return [3 /*break*/, 2];
                return [4 /*yield*/, globalThis.eveCore.changeCommentCategory(productId, interactionId, categoryId)];
            case 1:
                _b.sent();
                return [3 /*break*/, 3];
            case 2:
                console.error('Cannot change comment category yet. Finish the setup first.');
                _b.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.changeCommentCategory = changeCommentCategory;
var loadMoreComments = function (productId, interactionId, commentId) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.loadMoreComments)) return [3 /*break*/, 2];
                return [4 /*yield*/, globalThis.eveCore.loadMoreComments(productId, interactionId, commentId)];
            case 1:
                _b.sent();
                return [3 /*break*/, 3];
            case 2:
                console.error('Cannot load more comments yet. Finish the setup first.');
                _b.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.loadMoreComments = loadMoreComments;
var postCommentReaction = function (productId, interactionId, commentId, reaction) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.postCommentReaction)) return [3 /*break*/, 2];
                return [4 /*yield*/, globalThis.eveCore.postCommentReaction(productId, interactionId, commentId, reaction)];
            case 1:
                _b.sent();
                return [3 /*break*/, 3];
            case 2:
                console.error('Cannot post comment reaction yet. Finish the setup first.');
                _b.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.postCommentReaction = postCommentReaction;
var focus = function (productId, groupId, interactionId, stepId) {
    var _a;
    if ((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.focus) {
        globalThis.eveCore.focus(productId, groupId, interactionId, stepId);
    }
    else {
        console.error('Cannot focus step yet. Finish the setup first.');
    }
};
exports.focus = focus;
var blur = function () {
    var _a;
    if ((_a = globalThis.eveCore) === null || _a === void 0 ? void 0 : _a.blur) {
        globalThis.eveCore.blur();
    }
    else {
        console.error('Cannot blur step yet. Finish the setup first.');
    }
};
exports.blur = blur;
__exportStar(require("./types"), exports);
