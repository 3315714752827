import { FC } from 'react';
import { FlowGroup } from '@data/eve-sdk-web';
import { useConfig } from '../utils/config';
import { getColorForIndex, parseColors } from '../utils/colors';
import { LaneTeaser } from './LaneTeaser';

import styles from './Lane.module.scss';

export type LaneProps = {
  groups: FlowGroup[];
  onShowGroup: (group: FlowGroup) => void;
};

export const Lane: FC<LaneProps> = ({ groups, onShowGroup }) => {
  const { colors } = useConfig();
  const parsedColors = parseColors(colors ?? []);

  return groups.length ? (
    <div className={styles.lane}>
      <div className={styles['lane__items']}>
        {groups.map((group, index) => (
          <div key={group.id} className={styles['lane__item']}>
            <LaneTeaser
              group={group}
              color={getColorForIndex(parsedColors, index)}
              onClick={() => onShowGroup(group)}
            />
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
