import { FC, useEffect, useState } from 'react';

import styles from './Countdown.module.scss';

const CHART_RADIUS = 20;
const CHART_CIRCUMFERENCE = 2 * Math.PI * CHART_RADIUS;
const CHART_LINE_BASE = 2;
const CHART_LINE_ACTIVE = 3;
const CHART_SIZE = CHART_RADIUS * 2 + CHART_LINE_ACTIVE;

export type CountdownProps = {
  duration: number;
};

export const Countdown: FC<CountdownProps> = ({ duration }) => {
  const [counter, setCounter] = useState<number>(duration);
  const share = (100 / duration) * counter;

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter]);

  return (
    <div className={styles.countdown}>
      <svg
        className={styles['countdown__chart']}
        viewBox={`0 0 ${CHART_SIZE} ${CHART_SIZE}`}>
        <g
          fill="none"
          transform={`translate(${CHART_LINE_ACTIVE / 2}, ${
            CHART_LINE_ACTIVE / 2
          })`}>
          <circle
            className={styles['countdown__chart-line']}
            strokeWidth={CHART_LINE_BASE}
            cx={CHART_RADIUS}
            cy={CHART_RADIUS}
            r={CHART_RADIUS}
          />
          <circle
            className={styles['countdown__chart-active']}
            strokeWidth={CHART_LINE_ACTIVE}
            cx={CHART_RADIUS}
            cy={CHART_RADIUS}
            r={CHART_RADIUS}
            strokeDasharray={`${
              CHART_CIRCUMFERENCE * (share / 100)
            }, ${CHART_CIRCUMFERENCE}`}
          />
        </g>
      </svg>
      <div className={styles['countdown__label']}>{counter}</div>
    </div>
  );
};
