import { SignJWT } from 'jose';
import { nanoid } from 'nanoid';
import { getItem, setItem } from './storage';

const secret = new TextEncoder().encode(
  atob(process.env.REACT_APP_JWT_SECRET ?? ''),
);

const getOrCreateGuestUserId = (): string => {
  let userId = getItem<string>('session');
  if (userId) {
    return userId;
  }
  userId = nanoid();
  setItem('session', userId);
  return userId;
};

export const generateGuestToken = async (context: string): Promise<string> => {
  const userId = getOrCreateGuestUserId();
  const jwt = await new SignJWT({
    fn: 'Guest',
    bd: '0000-00-00',
    pid: context,
  })
    .setProtectedHeader({
      alg: 'HS256',
    })
    .setIssuedAt()
    .setSubject(userId)
    .setIssuer('eve:sdk:web:v1')
    .setExpirationTime('2h')
    .sign(secret);

  return jwt;
};
